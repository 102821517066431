import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "./Button";
import { NavLink, useNavigate, useLocation } from "react-router-dom";

import { useModalStore } from "../hooks/useModalStore";
import { useStore } from "../hooks/useStore";
import "../styles/colors.css";

var links = ["instructions", "about", "faq", "feedback", "ranking"];

function DesktopMenuBar() {
  const { t } = useTranslation("menubar");
  const openInstructionsModal = useModalStore(
    (state) => state.openInstructionsModal
  );
  const openFeedbackModal = useModalStore((state) => state.openFeedbackModal);
  const setFeedbackLocation = useStore((state) => state.setFeedbackLocation);
  const instructionsViewed = useStore((state) => state.instructionsViewed);
  const location = useLocation();
  const navigate = useNavigate();

  const showActionButton = ![
    "/play",
    "/play-2",
    "/consent",
    "/survey",
    "/results",
  ].includes(location.pathname);

  const clickHandler = (e, link) => {
    if (link === "instructions") {
      e.preventDefault();
      openInstructionsModal();
    }
    if (link === "feedback") {
      e.preventDefault();
      setFeedbackLocation({ location: location.pathname });
      openFeedbackModal();
    }
  };
  const buttonClickHandler = () => {
    if (instructionsViewed) {
      navigate("/play");
    } else {
      openInstructionsModal();
    }
  };
  return (
    <div className="m-auto flex h-12 md:h-21 py-8 px-16 justify-between items-center">
      <NavLink
        key="homeIcon"
        to="/"
        className="flex justify-start items-center hover:transform hover:transform-scale-98 hover:cursor-pointer"
      >
        <img src="/images/logo.svg" alt="Last Haven Logo" />
      </NavLink>
      <div className="flex justify-between">
        <NavLink
          key="home"
          to="/"
          className={({ isActive }) =>
            `px-4 text-4 font-600 ${
              isActive ? "underline text-lastHaven-primary-main" : ""
            }
            hover:underline`
          }
        >
          {t(`home`)}
        </NavLink>
        {links.map((link, ii) => (
          <MenuItem
            key={ii}
            t={t}
            id={ii}
            link={`/${link}`}
            onClick={(e) => clickHandler(e, link)}
          />
        ))}
      </div>
      <Button
        text={t("action-button-label")}
        onClick={buttonClickHandler}
        disabled={!showActionButton}
        className={`${showActionButton ? "visible" : "invisible"}`}
      />
    </div>
  );
}

export function MobileMenuBar({ t }) {
  const [isOpen, setIsOpen] = useState(false);
  const [slidingOut, setSlidingOut] = useState(false);
  const openInstructionsModal = useModalStore(
    (state) => state.openInstructionsModal
  );
  const openFeedbackModal = useModalStore((state) => state.openFeedbackModal);
  const instructionsViewed = useStore((state) => state.instructionsViewed);
  const location = useLocation();
  const setFeedbackLocation = useStore((state) => state.setFeedbackLocation);

  const showActionButton = ![
    "/play",
    "/play-2",
    "/consent",
    "/survey",
    "/results",
  ].includes(location.pathname);

  // This effect the slidingOut timeout but not the unocss className
  // You need to set className to match because unoCSS doesn't know
  // Js vars at bundle time. Making the timeout shorter than the animation
  // stops menu flickering back in sometimes.
  const ANIMATION_DURATION = 250;
  useEffect(() => {
    var timeout;
    if (isOpen) {
      setSlidingOut(true);
    } else {
      timeout = setTimeout(() => {
        setSlidingOut(false);
      }, ANIMATION_DURATION - 50);
    }
    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [isOpen, ANIMATION_DURATION]);
  const onSelection = () => {
    setIsOpen(false);
  };
  return (
    <div className="relative">
      <div className="h-72px min-w-375px w-screen">
        <div className="absolute flex h-72px min-w-375px font-700 w-screen justify-between items-center p-4 z-[10] bg-lastHaven-gray-50">
          <NavLink
            key="home"
            to="/"
            className="flex justify-start hover:text-lastHaven-gray-600"
            onClick={onSelection}
          >
            <img src="/images/logo.svg" alt="Last Haven Logo" />
          </NavLink>
          <button
            onClick={() => setIsOpen((a) => !a)}
            className="w-10 h-10 rounded-2 focus:(outline-none transform scale-[0.96]) hover:(transform scale-[0.96])"
            tabIndex={0}
          >
            <img
              src={
                isOpen
                  ? "/images/mobile-menu-close.svg"
                  : "/images/mobile-menu.svg"
              }
              alt="mobile menu"
              className={"w-10 h-10 rounded-2"}
            />
          </button>
        </div>
      </div>

      <div
        className={`absolute ${
          isOpen ? "animate-slide-in-down" : "animate-slide-out-up"
        } animate-duration-250 z-2`}
      >
        {(isOpen || slidingOut) && (
          <div className="min-w-375px w-screen bg-lastHaven-white rounded-b-4">
            <div className="min-w-375-px w-screen flex flex-col text-center p-4">
              <NavLink
                key={"home"}
                to={`/`}
                state={{ from: location.pathname }}
                className={({ isActive }) =>
                  `block text-lastHaven-black my-1 py-4 font-600 capitalize hover:underline ${
                    isActive
                      ? "underline text-lastHaven-primary-main bg-lastHaven-primary-10"
                      : ""
                  }`
                }
                onClick={onSelection}
              >
                {t(`home`)}
              </NavLink>
              {showActionButton && (
                <NavLink
                  key={"play"}
                  to={`/play`}
                  state={{ from: location.pathname }}
                  className={({ isActive }) =>
                    `block text-lastHaven-black my-1 py-4 font-600 capitalize hover:underline ${
                      isActive
                        ? "underline text-lastHaven-primary-main bg-lastHaven-primary-10"
                        : ""
                    }`
                  }
                  onClick={onSelection}
                >
                  {t(`play`)}
                </NavLink>
              )}
              {[...links].map((link, ii) => {
                const clickHandler = (e) => {
                  if (
                    link === "instructions" ||
                    (!instructionsViewed && link === "play")
                  ) {
                    e.preventDefault();
                    openInstructionsModal();
                  }
                  if (link === "feedback") {
                    e.preventDefault();
                    setFeedbackLocation({ location: location.pathname });
                    openFeedbackModal();
                  }
                  onSelection();
                };

                return (
                  <NavLink
                    key={ii}
                    to={`/${link}`}
                    state={{ from: location.pathname }}
                    className={({ isActive }) =>
                      `block text-lastHaven-black my-1 py-4 font-600 capitalize hover:underline ${
                        isActive
                          ? "underline text-lastHaven-primary-main bg-lastHaven-primary-10"
                          : ""
                      }`
                    }
                    onClick={clickHandler}
                  >
                    {t(`label-${ii + 1}`)}
                  </NavLink>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export function MenuBar() {
  const { t } = useTranslation("menubar");

  return (
    <>
      <div className="hidden xl:block">
        <DesktopMenuBar t={t} />
      </div>
      <div className="xl:hidden">
        <MobileMenuBar t={t} />
      </div>
    </>
  );
}

function MenuItem({ t, id, link, onClick }) {
  const location = useLocation();
  const instructionsModalIsOpen = useModalStore(
    (state) => state.instructionsModalIsOpen
  );
  const feedbackModalIsOpen = useModalStore(
    (state) => state.feedbackModalIsOpen
  );

  return (
    <NavLink
      to={link}
      onClick={onClick}
      state={{ from: location.pathname }}
      className={({ isActive }) =>
        `px-4 text-4 font-600 ${
          (!instructionsModalIsOpen && isActive) ||
          (link === "/instructions" && instructionsModalIsOpen)
            ? "underline text-lastHaven-primary-main"
            : ""
        } ${
          (!feedbackModalIsOpen && isActive) ||
          (link === "/feedback" && feedbackModalIsOpen)
            ? "underline text-lastHaven-primary-main"
            : ""
        }
        hover:underline`
      }
    >
      {t(`label-${id + 1}`)}
    </NavLink>
  );
}
