import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Button } from "../components/Button";
import { Dots } from "../components/index";
import { useModalStore } from "../hooks/useModalStore";
import { useStore } from "../hooks/useStore";
import { Modal } from "../components/Modal";
import {
  NUM_INSTRUCTION_STEPS,
  NUM_DILEMMAS,
} from "@last-haven/shared/constants";

const NavigationButtons = ({ onNext, onBack, step }) => {
  const { t } = useTranslation("instructions");
  const primaryButtonLabel =
    step === NUM_INSTRUCTION_STEPS - 1
      ? t("final-button-label")
      : t("primary-button-label");
  const secondaryButtonLabel = t("secondary-button-label");
  return (
    <div className="flex justify-between items-center mt-6 w-full h-54px">
      <Button
        className={`${step === 0 ? "invisible" : ""} min-w-92px w-92px gap-2`}
        secondary
        disabled={step === 0}
        onClick={onBack}
        text={secondaryButtonLabel}
      />
      <div className="flex items-center h-54px">
        <Dots active={step} count={NUM_INSTRUCTION_STEPS} />
      </div>
      <Button
        className="min-w-92px w-92px gap-2"
        onClick={onNext}
        text={primaryButtonLabel}
      />
    </div>
  );
};

const useHandlers = (onNext) => {
  const [step, setStep] = useState(0);
  const setInstructionsViewed = useStore(
    (state) => state.setInstructionsViewed
  );
  const nextHandler = () => {
    if (step < NUM_INSTRUCTION_STEPS - 1) {
      setStep((s) => s + 1);
    } else {
      if (onNext) {
        onNext();
        setInstructionsViewed();
      }
    }
  };

  const backHandler = () => {
    if (step > 0) {
      setStep((s) => s - 1);
    }
  };

  return { step, nextHandler, backHandler };
};

export function Instructions({ onClose }) {
  const { t } = useTranslation("instructions");

  const { step, nextHandler, backHandler } = useHandlers(onClose);

  return (
    <div
      key={`instruction-${step + 1}`}
      className="max-h-[90svh] max-sm:w-screen sm:w-full overflow-y-auto"
    >
      <div className="w-357px sm:w-742px max-sm:h-[100svh] max-sm:min-h-646px  max-sm:mx-auto pb-4 px-4 sm:px-12 sm:py-10">
        <div className="font-sans text-6 font-600 mb-2 leading-8 w-full text-center">
          {t(`title-${step + 1}`)}
        </div>
        <div className="flex flex-col justify-center items-center w-full sm:p-24px">
          <img
            className="h-343px w-343px sm:h-388px sm:w-388px"
            src={`images/intro/intro-${step + 1}.svg`}
            alt="Instructions"
          />
        </div>
        <div className="font-sans w-full text-center text-4.5 leading-8 gap-0.5">
          {t(`instruction-${step + 1}`, { numDilemmas: NUM_DILEMMAS })}
        </div>
        <NavigationButtons
          onNext={nextHandler}
          onBack={backHandler}
          step={step}
        />
      </div>
    </div>
  );
}

export function InstructionsModal() {
  const navigate = useNavigate();
  const open = useModalStore((state) => state.instructionsModalIsOpen);
  const close = useModalStore((state) => state.closeInstructionsModal);

  const closeHandler = () => {
    navigate("/play");
    close();
  };

  return (
    <Modal open={open} onClose={closeHandler}>
      <Instructions onClose={closeHandler} />
    </Modal>
  );
}
