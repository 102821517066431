import { create } from "zustand";
import { produce } from "immer";

interface IStore {
  open: (modalName: string) => void;
  close: (modalName: string) => void;
  instructionsModalIsOpen: boolean;
  openInstructionsModal: () => void;
  closeInstructionsModal: () => void;
  triggerWarningModalIsOpen: boolean;
  openTriggerWarningModal: () => void;
  closeTriggerWarningModal: () => void;
  feebackModalIsOpen: boolean;
  openFeedbackModal: () => void;
  closeFeedbackModal: () => void;
  socialShareModalIsOpen: boolean;
  openSocialShareModal: () => void;
  closeSocialShareModal: () => void;
  afterRankingModalIsOpen: boolean;
  openAfterRankingModal: () => void;
  closeAfterRankingModal: () => void;
}

export const useModalStore = create<IStore>((set, get) => ({
  open: (modalName) => set(produce((state) => { state[`${modalName}ModalIsOpen`] = true; })),
  close: (modalName) => set(produce((state) => { state[`${modalName}ModalIsOpen`] = false; })),
  instructionsModalIsOpen: false,
  openInstructionsModal: () => get().open("instructions"),
  closeInstructionsModal: () => get().close("instructions"),
  triggerWarningModalIsOpen: false,
  openTriggerWarningModal: () => get().open("triggerWarning"),
  closeTriggerWarningModal: () => get().close("triggerWarning"),
  feebackModalIsOpen: false,
  openFeedbackModal: () => get().open("feedback"),
  closeFeedbackModal: () => get().close("feedback"),
  socialShareModalIsOpen: false,
  openSocialShareModal: () => get().open("socialShare"),
  closeSocialShareModal: () => get().close("socialShare"),
  afterRankingModalIsOpen: false,
  openAfterRankingModal: () => get().open("afterRanking"),
  closeAfterRankingModal: () => get().close("afterRanking"),
}));