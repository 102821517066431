import React, { useState } from "react";
import { useNavigate, useLoaderData } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Popup } from "reactjs-popup";

import { useStore } from "../hooks/useStore";
import { useModalStore } from "../hooks/useModalStore";
import { speciesClassMap } from "@last-haven/shared/constants";
import { Button } from "../components/Button";
import Info from "../components/icons/Info";

const forTranslation = (key) => key.toLowerCase().replace(/ /g, "-");
const Tooltip = ({ tooltip }) => {
  if (!tooltip) return null;
  return (
    <Popup
      trigger={(open) => (
        <div className="ml-2 text-lastHaven-gray-600">
          <Info className="font-700" />
        </div>
      )}
      on={["hover", "click"]}
      position="bottom right"
      closeOnDocumentClick
      className="tooltip"
      lockScroll
    >
      <span>{tooltip}</span>
    </Popup>
  );
};

const Range = ({
  value1,
  value2,
  min = 0,
  max = 1,
  label1 = "",
  label2 = "",
}) => {
  const percentage1 = Math.max(
    0,
    Math.min(100, ((value1 - min) / (max - min)) * 100)
  );
  const percentage2 = Math.max(
    0,
    Math.min(100, ((value2 - min) / (max - min)) * 100)
  );

  return (
    <div className="relative max-sm:max-w-90% max-sm:w-90% sm:w-full h-6 mx-2">
      <div
        style={{
          left: `0%`,
          width: `100%`,
        }}
        className="absolute h-3 bg-lastHaven-gray-100 rounded-full"
      />
      <div
        style={{ left: "0%", top: "-60%" }}
        className="absolute w-1 h-3 bg-lastHaven-gray-100 rounded-1"
      />
      <div
        style={{ left: "50%", top: "-60%", transform: "translateX(-50%)" }}
        className="absolute w-1 h-3 bg-lastHaven-gray-100 rounded-1"
      />
      <div
        style={{ right: "0%", top: "-60%" }}
        className="absolute w-1 h-3 bg-lastHaven-gray-100 rounded-1"
      />
      <div
        style={{
          left: `${percentage1}%`,
          bottom: "25%",
          transform: "translateX(-50%)",
        }}
        className="absolute w-6 h-6 bg-lastHaven-primary-600 rounded-full border-2 border-lastHaven-white z-2"
      >
        {label1 && (
          <span
            style={{
              bottom: "120%",
              left: "50%",
              transform: "translateX(-50%)",
            }}
            className="absolute bg-lastHaven-black text-lastHaven-white text-3 rounded-2 px-2 py-1 text-center font-700"
          >
            {label1}
            <span
              style={{
                top: "80%",
                left: "50%",
                transform: "translateX(-50%) rotate(45deg)",
              }}
              className="absolute w-2 h-2 bg-lastHaven-black"
            ></span>
          </span>
        )}
      </div>
      <div
        style={{
          left: `${percentage2}%`,
          bottom: "25%",
          transform: "translateX(-50%)",
        }}
        className="absolute w-6 h-6 bg-lastHaven-system-warning rounded-full border-2 border-lastHaven-white z-1"
      >
        {label2 && (
          <span
            style={{
              bottom: "-140%",
              left: "50%",
              transform: "translateX(-50%)",
            }}
            className="absolute  bg-lastHaven-black text-lastHaven-white text-3 rounded-2 px-2 py-1 text-center font-700"
          >
            {label2}
            <span
              style={{
                bottom: "80%",
                left: "50%",
                transform: "translateX(-50%) rotate(45deg)",
              }}
              className="absolute w-2 h-2 bg-lastHaven-black text-lastHaven-white"
            ></span>
          </span>
        )}
      </div>
    </div>
  );
};

const RangeEnd = ({ image, label }) => (
  <div className="max-sm:hidden flex flex-col items-center">
    <img className="h-80px min-w-80px" src={image} alt={label} />
    <h2 className="max-w-96px break-words hyphens-auto">{label}</h2>
  </div>
);

const MobileRangeEnd = ({ image, label, right = true }) => (
  <div
    className={`sm:hidden flex items-center bottom-1 ${
      right ? "right-0" : "left-0"
    } absolute`}
  >
    {right && (
      <h2 className="max-w-96px break-words hyphens-auto text-3 leading-4">
        {label}
      </h2>
    )}
    <img className="h-40px min-w-40px" src={image} alt={label} />
    {!right && (
      <h2 className="max-w-96px break-words hyphens-auto text-3 leading-4">
        {label}
      </h2>
    )}
  </div>
);

const Result = ({
  title = "",
  subtitle,
  scores: { user, others },
  scoreLabel1 = "",
  scoreLabel2 = "",
  labels: { left = "avoided", right = "sought" },
  images,
  tooltip,
  min = -1,
  max = 1,
}) => (
  <div className="flex flex-col w-full">
    {title && <div className="text-6 font-700 pb-2 mx-auto">{title}</div>}
    <div className="flex justify-center items-center mx-auto font-700 py-2">
      {subtitle}
      <Tooltip tooltip={tooltip} />
    </div>
    <div className="flex justify-center items-center h-24 sm:h-31 relative">
      <RangeEnd image={images.left} label={left} />
      <MobileRangeEnd image={images.left} label={left} right={false} />
      <Range
        value1={user}
        value2={others}
        label1={scoreLabel1}
        label2={scoreLabel2}
        min={min}
        max={max}
      />
      <MobileRangeEnd image={images.right} label={right} />
      <RangeEnd image={images.right} label={right} />
    </div>
  </div>
);
const Card = ({ title, text, image }) => (
  <div className="flex flex-col sm:w-425px sm:h-352px bg-lastHaven-white rounded-4 justify-center items-center sm:py-8 shadow-[0_2px_20px_0_rgba(0,0,0,0.1)]">
    <div className="font-700 text-3.5 sm:text-4.5 text-center">{title}</div>
    <div className="sm:py-4">
      <img className="sm:h-207px sm:w-207px" src={image} alt={text} />
    </div>
    <div className="text-3 sm:text-4">{text}</div>
  </div>
);

export function ResultsSummary() {
  const { t } = useTranslation("results");
  const resetSession = useStore((state) => state.resetSession);
  const surveyCompleted = useStore((state) => state.surveyCompleted);
  const openSocialShareModal = useModalStore(
    (state) => state.openSocialShareModal
  );
  const navigate = useNavigate();
  const {
    mostSavedClass,
    leastSavedClass,
    humansVsSpeciesScore,
    effectivenessScore,
    charismaticSpeciesScore,
    ecologicallyImportantSpeciesScore,
    economicImpactScore,
    ecologicalImpactScore,
    others,
  } = useLoaderData();

  const [reseting, setReseting] = useState(false);
  // TODO: remove after testing

  const restartPlay = async () => {
    setReseting(true);
    await resetSession();
    navigate("/play");
  };

  const share = () => {
    openSocialShareModal();
  };

  return (
    <div>
      <div className="flex flex-col p-4 sm:p-16">
        <div className="text-8 font-600 py-4">{t("title")}</div>
        <div>{t("subtitle")}</div>
        <div className="h-15 w-full" />
        <div className="flex justify-center items-center sm:h-384px">
          {!!mostSavedClass && (
            <Card
              title={t("most-saved-title")}
              text={t(`species-class.${forTranslation(mostSavedClass)}`)}
              image={speciesClassMap[mostSavedClass]?.image}
            />
          )}
          <div className="w-4" />
          {!!leastSavedClass && (
            <Card
              title={t("least-saved-title")}
              text={t(`species-class.${forTranslation(leastSavedClass)}`)}
              image={speciesClassMap[leastSavedClass]?.image}
            />
          )}
        </div>
        <div className="h-10 w-full" />
        <Result
          title={t("preference-1.title")}
          subtitle={t("preference-1.subtitle")}
          scores={{
            user: humansVsSpeciesScore,
            others: others?.humansVsSpeciesScore ?? 0.5,
          }}
          scoreLabel1={t("you")}
          scoreLabel2={t("others")}
          labels={{
            left: t("preference-1.left-label"),
            right: t("preference-1.right-label"),
          }}
          images={{
            left: "/images/species.svg",
            right: "/images/people.svg",
          }}
          tooltip={t("tooltip-species-humans")}
          min={0}
          max={1}
        />
        <div className="h-10 w-full" />
        <Result
          title={t("preference-2.title")}
          subtitle={t("preference-2.subtitle")}
          scores={{
            user: effectivenessScore,
            others: others?.effectivenessScore ?? 0,
          }}
          scoreLabel1={t("you")}
          scoreLabel2={t("others")}
          labels={{
            left: t("preference-2.left-label"),
            right: t("preference-2.right-label"),
          }}
          images={{
            left: "/images/fewest.svg",
            right: "/images/most.svg",
          }}
          tooltip={t("tooltip-effectiveness")}
        />
        <div className="h-10 w-full" />
        <Result
          title={t("preference-3.title")}
          subtitle={t("preference-3.subtitle")}
          scores={{
            user: charismaticSpeciesScore,
            others: others?.charismaticSpeciesScore ?? 0,
          }}
          scoreLabel1={t("you")}
          scoreLabel2={t("others")}
          labels={{
            left: t("preference-3.left-label"),
            right: t("preference-3.right-label"),
          }}
          images={{
            left: "/images/fewest.svg",
            right: "/images/most.svg",
          }}
          tooltip={t("tooltip-charisma")}
        />
        <Result
          title=""
          subtitle={t("preference-4.subtitle")}
          scores={{
            user: ecologicallyImportantSpeciesScore,
            others: others?.ecologicallyImportantSpeciesScore ?? 0,
          }}
          scoreLabel1={t("you")}
          scoreLabel2={t("others")}
          labels={{
            left: t("preference-4.left-label"),
            right: t("preference-4.right-label"),
          }}
          images={{
            left: "/images/fewest.svg",
            right: "/images/most.svg",
          }}
          tooltip={t("tooltip-ecological-importance")}
        />
        <div className="h-10 w-full" />
        <Result
          title={t("preference-5.title")}
          subtitle={t("preference-5.subtitle")}
          scores={{
            user: economicImpactScore,
            others: others?.economicImpactScore ?? 0,
          }}
          scoreLabel1={t("you")}
          scoreLabel2={t("others")}
          labels={{
            left: t("preference-5.left-label"),
            right: t("preference-5.right-label"),
          }}
          images={{
            left: "/images/fewest.svg",
            right: "/images/most.svg",
          }}
          tooltip={t("tooltip-economic-impact")}
        />
        <Result
          title=""
          subtitle={t("preference-6.subtitle")}
          scores={{
            user: ecologicalImpactScore,
            others: others?.ecologicalImpactScore ?? 0,
          }}
          scoreLabel1={t("you")}
          scoreLabel2={t("others")}
          labels={{
            left: t("preference-6.left-label"),
            right: t("preference-6.right-label"),
          }}
          images={{
            left: "/images/fewest.svg",
            right: "/images/most.svg",
          }}
          tooltip={t("tooltip-ecological-impact")}
        />
      </div>
      {!surveyCompleted && (
        <div className="flex flex-col justify-center items-center h-118px w-full">
          <div className="leading-6 w-full text-center pb-4 font-600">
            {t("survey-prompt")}
          </div>
          <Button
            className="mr-2"
            onClick={() => navigate("/survey")}
            text={t("survey-button-label")}
            disabled={reseting}
          />
        </div>
      )}
      <div className="flex justify-center items-center h-118px w-full mb-15">
        <Button
          className="max-sm:h-58px mr-2 py-4 px-6 text-4.5 leading-6"
          secondary
          onClick={restartPlay}
          text={t("play-again")}
          disabled={reseting}
        />
        <Button
          className="max-sm:h-58px ml-2 py-4 px-6 text-4.5 leading-6"
          onClick={share}
          text={t("share-results")}
          disabled={reseting}
        />
      </div>
    </div>
  );
}
