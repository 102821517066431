import { categoryMap, Species, Buildings } from './constants.js';

interface WeightedItem<T> {
  item: T;
  cumulativeWeight: number;
}

// Type guard to check if categoryName is "species"
function isSpeciesCategory(categoryName: string): categoryName is "species" {
  return categoryName === "species";
}

// Type guard to check if categoryName is "buildings"
function isBuildingsCategory(categoryName: string): categoryName is "buildings" {
  return categoryName === "buildings";
}

// Conditional type to infer the return type based on categoryName
type CategoryType<T extends string> = T extends "species" ? Species[] : Buildings[];

// Extract the element type from the array type in CategoryType<T>
type ElementType<T extends string> = T extends "species" ? Species : Buildings;

export function sampleSingleCategory<T extends "species" | "buildings">(categoryName: T): Species[] | Buildings[] {
  let candidates: any[] = [] as CategoryType<T>;

  const categories = Object.keys(categoryMap);
  for (let i = 0; i < categories.length; i++) {
    const category = categoryMap[categories[i]];
    const newCandidates = (
      isSpeciesCategory(categoryName) ? shuffleArray(category.species) : shuffleArray(category.buildings)
    ).slice(0, category.count) as CategoryType<T>;
    candidates.push(...newCandidates);
  }

  return shuffleArray(candidates) as CategoryType<T>;
}

export function sampleExactNumbers(): { species: Species[], buildings: Buildings[] } {
  let species: Species[] = [];
  let buildings: Buildings[] = [];

  const categories = Object.keys(categoryMap);
  for (let i = 0; i < categories.length; i++) {
    const category = categoryMap[categories[i]];
    const newSpecies = shuffleArray(category.species).slice(0, category.count);
    const newBuildings = shuffleArray(category.buildings).slice(0, category.count);
    species.push(...newSpecies);
    buildings.push(...newBuildings);
  }
  return {species: shuffleArray(species), buildings: shuffleArray(buildings)};
}
const buildWeightedList = <T>(items: T[], weights: number[]): WeightedItem<T>[] => {
  let cumulativeWeight = 0;
  return items.map((item, index) => {
    cumulativeWeight += weights[index];
    return { item, cumulativeWeight };
  });
};

const binarySearch = <T>(weightedItems: WeightedItem<T>[], value: number): T => {
  let start = 0;
  let end = weightedItems.length - 1;

  while (start <= end) {
    const mid = Math.floor((start + end) / 2);
    if (weightedItems[mid].cumulativeWeight < value) {
      start = mid + 1;
    } else if (weightedItems[mid].cumulativeWeight > value && (mid === 0 || weightedItems[mid - 1].cumulativeWeight < value)) {
      return weightedItems[mid].item;
    } else {
      end = mid - 1;
    }
  }

  throw new Error('Failed to get a sample');
};

function sampleFromWeightedList<T>(weightedItems: WeightedItem<T>[]): T {
  const max = weightedItems[weightedItems.length - 1].cumulativeWeight;
  const random = Math.random() * max;
  return binarySearch(weightedItems, random);
}

function sampleWithoutReplacement<T>(weightedItems: WeightedItem<T>[]): [T, WeightedItem<T>[]] {
  const max = weightedItems[weightedItems.length - 1].cumulativeWeight;
  const random = Math.random() * max;
  const item = binarySearch(weightedItems, random);
  const newWeightedItems = weightedItems.filter((wi) => wi.item !== item);
  return [item, newWeightedItems];
}

export function sample<T>(items: T[], weights: number[]): T {
  const weightedItems = buildWeightedList(items, weights);
  return sampleFromWeightedList(weightedItems);
}

export function shuffleArray<T>(array: T[]): T[] {
  const result = [...array];
  for (let i = result.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [result[i], result[j]] = [result[j], result[i]];
  }
  return result;
}