import React, { useState, useEffect } from "react";

import ChevronDown from "./icons/ChevronDown";
import { COUNTRIES } from "@last-haven/shared/constants";
import "../styles/colors.css";

export function SurveyQuestion({
  small = false,
  number,
  displayNumber = null,
  parentNumber = null,
  t,
  onAnswer,
  answers = [],
}) {
  const q = parentNumber
    ? `question-${parentNumber}.question-${number}`
    : `question-${number}`;
  const question = t(`${q}.question`);
  const type = t(`${q}.type`);
  let child;
  const answerHandler = (answer) => {
    onAnswer({
      index: number,
      displayIndex: displayNumber,
      questionId: q,
      question,
      answer,
      created: Date.now(),
    });
  };
  switch (type) {
    case "radio":
      child = (
        <RadioQuestion
          small
          number={number}
          q={q}
          t={t}
          onAnswer={answerHandler}
        />
      );
      break;
    case "slider":
      child = (
        <SliderQuestion
          small
          number={number}
          q={q}
          t={t}
          onAnswer={answerHandler}
        />
      );
      break;
    case "dropdown":
      child = (
        <DropdownQuestion
          small
          number={number}
          q={q}
          t={t}
          onAnswer={answerHandler}
        />
      );
      break;
    case "country-dropdown":
      child = (
        <CountryDropdownQuestion
          small
          number={number}
          q={q}
          t={t}
          onAnswer={answerHandler}
        />
      );
      break;
    case "number":
      child = (
        <NumberQuestion
          small
          number={number}
          q={q}
          t={t}
          onAnswer={answerHandler}
        />
      );
      break;
    case "text":
      child = (
        <TextQuestion
          small
          number={number}
          q={q}
          t={t}
          onAnswer={answerHandler}
        />
      );
      break;
    case "checklist":
      child = (
        <CheckListQuestion
          small
          number={number}
          q={q}
          t={t}
          onAnswer={answerHandler}
          answers={answers}
        />
      );
      break;
    case "question-group":
      child = (
        <QuestionGroup number={number} q={q} t={t} onAnswer={answerHandler} />
      );
      break;
    default:
      child = <div>Unknown question type: {type}</div>;
  }
  return (
    <div className={`${small ? "" : "pb-6"} w-full`}>
      <div
        className={`text-lastHaven-black ${
          small ? "mt-2 pt-4" : "text-5 font-700 leading-7"
        }`}
      >
        {displayNumber || number}. {t(`${q}.question`)}
      </div>
      <div className="py-4">{child}</div>
    </div>
  );
}

function NumberQuestion({ small, number, q, t, onAnswer }) {
  const [error, setError] = useState("");
  const questionId = `answer-${number}`;
  const placeholder = t(`${q}.placeholder`);
  const max = parseInt(t(`${q}.max`));
  const min = parseInt(t(`${q}.min`));
  const changeHandler = (e) => {
    const value = e.target.value;
    if (value === "" || value < min || value > max) {
      setError(t(`${q}.error`));
    } else {
      onAnswer(parseInt(value));
      setError("");
    }
  };
  return (
    <div>
      <input
        type="number"
        pattern="\d*" // only allow digits
        name={questionId}
        placeholder={placeholder}
        className="font-sans p-4 rounded-2 focus:(outline-none border-lastHaven-primary-600 border-1) border-lastHaven-gray-100 "
        onChange={changeHandler}
        onKeyDown={(e) =>
          (e.key === "." || e.key === "," || e.key === "-") &&
          e.preventDefault()
        }
        tabIndex={0}
        aria-describedby={error ? `${questionId}-error` : undefined}
        aria-invalid={!!error}
      />
      {error && (
        <div className="font-sans font-700 text-3 text-lastHaven-system-error">
          {error}
        </div>
      )}
    </div>
  );
}

function TextQuestion({ small, number, q, t, onAnswer }) {
  const name = `answer-${number}`;
  const placeholder = t(`${q}.placeholder`);

  const changeHandler = (e) => {
    onAnswer(e.target.value);
  };
  return (
    <textarea
      name={name}
      placeholder={placeholder}
      rows="5"
      className="w-full h-24 p-2 bg-lastHaven-gray-50 border-2"
      style={{
        // using inline styles because unocss wasn't working
        borderRadius: "8px",
        borderColor: "#E0E0E0",
      }}
      onChange={changeHandler}
      onFocus={(e) => {
        e.target.style.outline = "none"; /* Remove default focus ring */
        e.target.style.borderColor =
          "var(--gray-100)"; /* Apply custom focus border color */
        e.target.style.boxShadow =
          "0 0 0 2px rgba(236, 68, 65, 0.1)"; /* Apply custom focus ring effect */
      }}
      onBlur={(e) => {
        e.target.style.borderColor =
          "#E0E0E0"; /* Reset the color back to normal */
        e.target.style.boxShadow = "none"; /* Reset the box shadow */
      }}
      tabIndex={0}
    />
  );
}

function RadioButton({
  value,
  label,
  placeholder,
  selected,
  setSelected,
  freeText,
  setFreeText,
  onAnswer,
  horizontal,
}) {
  const clickHandler = () => {
    setSelected(value);
    onAnswer(freeText || label);
  };
  const keyDownHandler = (e) => {
    if (e.key === "Enter" || e.key === " ") {
      e.preventDefault();
      clickHandler();
    }
  };
  const freeTextHandler = (e) => {
    setFreeText(e.target.value);
    onAnswer(e.target.value);
  };
  const showFreeText =
    value === selected && !placeholder.includes("placeholder");
  return (
    <>
      <div
        className={`flex ${
          horizontal ? "flex-col justify-center w-7 sm:w-14" : ""
        } items-center h-14 hover:cursor-pointer focus:(outline-none)`}
        onClick={clickHandler}
        onKeyDown={keyDownHandler}
        tabIndex={0}
        role="radio"
        aria-checked={selected === value}
        aria-labelledby={`radio-label-${value}`}
      >
        {selected === value ? (
          <img
            src="/images/checked-radio-button.svg"
            className="w-6 h-6"
            alt="Selected Radio Button"
          />
        ) : (
          <img
            src="/images/unchecked-radio-button.svg"
            className="w-6 h-6"
            alt="Radio Button"
          />
        )}
        <div
          id={`radio-label-${value}`}
          className={`flex items-center ${!horizontal ? "ml-2" : ""}`}
        >
          {label}
        </div>
      </div>
      {showFreeText && (
        <input
          className="ml-8 p-2 w-60 rounded-2 focus:(outline-none border-lastHaven-primary-600 border-1 border-rounded-2) border-lastHaven-gray-100"
          type="text"
          value={freeText}
          placeholder={placeholder}
          onChange={freeTextHandler}
          tabIndex={0}
          aria-label={"additonal-input"}
        />
      )}
    </>
  );
}

function RadioQuestion({ small, number, q, t, onAnswer }) {
  // const name = `answer-${number}`;
  const numberOfOptions = parseInt(t(`${q}.number-of-options`));
  const defaultSelection = t(`${q}.default-selection`);
  const horizontal = t(`${q}.horizontal`) === "true";
  const [selected, setSelected] = useState(defaultSelection);
  const [freeText, setFreeText] = useState("");

  // use empty deps to make this run only once on mount
  useEffect(() => {
    if (defaultSelection) {
      for (let ii = 1; ii <= numberOfOptions; ii++) {
        const value = t(`${q}.option-${ii}.value`);
        const label = t(`${q}.option-${ii}.label`);
        if (value === defaultSelection) {
          onAnswer(label);
          break;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFreeText("");
  }, [selected]);

  return (
    <div className="flex items-center">
      {horizontal && (
        <div className="flex items-center w-15 text-center">
          {t(`${q}.min-label`)}
        </div>
      )}
      <div className={`flex ${!horizontal ? "flex-col" : ""}`}>
        {Array(numberOfOptions)
          .fill(0)
          .map((option, ii) => (
            <RadioButton
              key={ii}
              value={t(`${q}.option-${ii + 1}.value`)}
              label={t(`${q}.option-${ii + 1}.label`)}
              placeholder={t(`${q}.option-${ii + 1}.placeholder`)}
              selected={selected}
              setSelected={setSelected}
              freeText={freeText}
              setFreeText={setFreeText}
              onAnswer={onAnswer}
              horizontal={horizontal}
            />
          ))}
      </div>
      {horizontal && (
        <div className="flex items-center w-15 text-center">
          {t(`${q}.max-label`)}
        </div>
      )}
    </div>
  );
}

const SliderQuestion = ({ small, number, q, t, onAnswer }) => {
  const name = `answer-${number}`;
  const minLabel = t(`${q}.min-label`);
  const maxLabel = t(`${q}.max-label`);
  const min = parseInt(t(`${q}.min`));
  const max = parseInt(t(`${q}.max`));
  const step = parseInt(t(`${q}.step`));
  const defaultSelection = !t(`${q}.default-selection`).includes(
    "default-selection"
  )
    ? parseInt(t(`${q}.default-selection`))
    : null;

  const [selected, setSelected] = useState(defaultSelection);

  const changeHandler = (e) => {
    const value = parseInt(e.target.value);
    setSelected(value);
    onAnswer(value);
  };

  const clickHandler = (e) => {
    if (!selected && parseInt(e.target.value) === midpoint) {
      setSelected(midpoint);
      onAnswer(midpoint);
    }
  };

  const midpoint = min + (max - min) / 2;
  return (
    <div className="flex w-full">
      <div className="flex flex-col min-w-94px max-w-94px w-94px my-auto">
        <label id={`min-label-${name}`}>{minLabel}</label>
      </div>
      <div className="flex flex-col justify-center w-full relative">
        <div className="flex justify-between px-4 min-h-4 items-center">
          {Array(1 + (max - min) / step)
            .fill(0)
            .map((option, ii) => (
              // This background is fine for Firefox but Safari struggle rendering this color.
              <div
                key={ii}
                className="bg-lastHaven-gray-100 min-w-1 min-h-2 h-2 rounded-2"
              />
            ))}
        </div>
        <input
          type="range"
          className="px-4 w-full"
          name={name}
          min={min}
          max={max}
          step={step}
          value={selected === null ? "" : selected}
          onChange={changeHandler}
          onClick={clickHandler}
          tabIndex={0}
          aria-labelledby={`min-label-${name} max-label-${name}`}
          aria-valuemin={min}
          aria-valuemax={max}
          aria-valuenow={selected}
          aria-valuetext={selected !== null ? selected.toString() : ""}
        />
        {!selected && (
          <span
            style={{
              bottom: "60%",
              left: "50%",
              transform: "translateX(-50%)",
            }}
            className="absolute bg-lastHaven-black text-lastHaven-white text-3 rounded-2 px-2 py-1 text-center font-700"
          >
            {t("slider-warning")}
            <span
              style={{
                top: "85%",
                left: "50%",
                transform: "translateX(-50%) rotate(45deg)",
              }}
              className="absolute w-2 h-2 bg-lastHaven-black"
            ></span>
          </span>
        )}
      </div>
      <div className="flex flex-col justify-center my-auto min-w-94px max-w-94px w-94px">
        <label id={`max-label-${name}`}>{maxLabel}</label>
      </div>
    </div>
  );
};

function DropdownQuestion({ small, number, q, t, onAnswer }) {
  const name = `answer-${number}`;
  const numberOfOptions = parseInt(t(`${q}.number-of-options`));
  const defaultSelection =
    t(`${q}.default-selection`) !== `${q}.default-selection`
      ? t(`${q}.default-selection`)
      : null;

  const [selected, setSelected] = useState(defaultSelection);

  const findLabel = (value) => {
    for (let ii = 1; ii <= numberOfOptions; ii++) {
      if (Number(value) === t(`${q}.option-${ii}.value`)) {
        return t(`${q}.option-${ii}.label`);
      }
    }
  };
  useEffect(() => {
    if (defaultSelection) {
      onAnswer(findLabel(defaultSelection));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeHandler = (e) => {
    const value = e.target.value;
    setSelected(value);
    onAnswer(findLabel(value));
  };

  return (
    <div className="relative inline-block max-sm:w-343px w-536px">
      <select
        name={name}
        value={selected || ""}
        onChange={changeHandler}
        className="appearance-none border-1 p-4 border-[#E0E0E0] rounded-2 min-w-280px w-full text-lastHaven-gray-600 focus:outline-none focus:border-none focus:ring-lastHaven-primary-600"
      >
        <option value="" disabled>
          {t(`${q}.placeholder`)}
        </option>
        {Array.from({ length: numberOfOptions }, (_, ii) => (
          <option key={ii} value={t(`${q}.option-${ii + 1}.value`)}>
            {t(`${q}.option-${ii + 1}.label`)}
          </option>
        ))}
      </select>
      <ChevronDown
        size={36}
        className="absolute right-3 top-3 text-lastHaven-gray-100 font-700 pointer-events-none"
      />
    </div>
  );
}

function CountryDropdownQuestion({ small, number, q, t, onAnswer }) {
  const name = `answer-${number}`;

  const [selected, setSelected] = useState("");

  const findLabel = (value) => {
    const country = COUNTRIES.find((country) => country.value === value);
    return country.label;
  };

  const changeHandler = (e) => {
    const value = e.target.value;
    setSelected(value);
    onAnswer(findLabel(value));
  };

  return (
    <div className="relative inline-block max-sm:w-343px w-536px">
      <select
        name={name}
        value={selected || ""}
        onChange={changeHandler}
        className="appearance-none border-1 p-4 border-[#E0E0E0] rounded-2 min-w-280px w-full text-lastHaven-gray-600 focus:outline-none focus:border-none focus:ring-lastHaven-primary-600 accent-lastHaven-primary-main"
      >
        <option value="" disabled>
          {t(`${q}.placeholder`)}
        </option>
        {COUNTRIES.map(({ label, value }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </select>
      <ChevronDown
        size={36}
        className="absolute right-3 top-3 text-lastHaven-gray-100 font-700 pointer-events-none"
      />
    </div>
  );
}
const CheckListQuestion = ({ small, number, q, t, onAnswer, answers }) => {
  const numberOfOptions = parseInt(t(`${q}.number-of-options`));

  const [selected, setSelected] = useState([]);
  const CheckBox = ({ value, label }) => {
    const changeHandler = () => {
      setSelected((s) => {
        return s.includes(value) ? s.filter((v) => v !== value) : [...s, value];
      });
      const newAnswers = answers.includes(label)
        ? answers.filter((v) => v !== label)
        : [...answers, label];
      onAnswer(newAnswers);
    };
    return (
      <div onClick={changeHandler} className="flex items-center">
        <input
          type="checkbox"
          className="mr-2 leading-tight h-6 w-6 hover:cursor-pointer accent-lastHaven-primary-main checked:color-lastHaven-gray-100"
          style={{
            // only large radius works via unocss
            // tried rounded-sm, rounded-4px, ...etc.
            borderRadius: "4px",
            // can't be set by the border className as expected
            // attemped border-lastHaven-gray-300, border-color-lastHaven-gray-300, color-lastHaven-gray-300
            borderColor: "var(--primary-main)",
          }}
          readOnly
          checked={selected.includes(value)}
          tabIndex={0}
          id={`checkbox-${value}`}
        />
        <label
          htmlFor={`checkbox-${value}`}
          className="block text-[16px] mb-0 hover:cursor-pointer"
        >
          {label}
        </label>
      </div>
    );
  };

  return (
    <div className="flex flex-col">
      {Array(numberOfOptions)
        .fill(0)
        .map((option, ii) => (
          <div key={ii} className="w-full h-14 gap-8 p-4">
            <CheckBox
              value={t(`${q}.option-${ii + 1}.value`)}
              label={t(`${q}.option-${ii + 1}.label`)}
            />
          </div>
        ))}
    </div>
  );
};

const QuestionGroup = ({ number, q, t, onAnswer }) => {
  const numberOfQuestions = parseInt(t(`${q}.number-of-questions`));

  const [answers, setAnswers] = useState([]);
  const answerHandler = (answer) => {
    const newAnswers = answers.some((ans) => ans.index === answer.index)
      ? answers.map((ans) => (ans.index === answer.index ? answer : ans))
      : [...answers, answer];
    setAnswers((answers) => {
      const newAnswers = answers.some((ans) => ans.index === answer.index)
        ? answers.map((ans) => (ans.index === answer.index ? answer : ans))
        : [...answers, answer];
      return newAnswers;
    });
    onAnswer(newAnswers);
  };

  return (
    <div className="flex flex-col pl-8">
      {Array(numberOfQuestions)
        .fill(0)
        .map((option, ii) => (
          <SurveyQuestion
            key={ii}
            parentNumber={number}
            number={ii + 1}
            t={t}
            onAnswer={answerHandler}
            answers={answers}
          />
        ))}
    </div>
  );
};
