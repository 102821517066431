import axios, { AxiosResponse, AxiosError } from 'axios';

interface ApiResponse<T = any> {
  success: boolean;
  data?: T;
  error?: AxiosError | String;
}

enum Resources {
  Users = "/api/users",
  Surveys = "/api/surveys",
  Responses = "/api/responses",
  Feedback = "/api/feedback",
  Scores = "/api/scores",
  Results = "/api/results",
  Rankings = "/api/rankings",
} 

const api = axios.create({
  baseURL: import.meta.env.VITE_BACKEND_BASE_URL
})

api.interceptors.response.use(
  response => response,
  error => {
    /* TODO: setup error in Zustand and use it here to open a toast
    notification.error({
      message: 'An error occurred',
      description: 'Something went wrong while making a request. Please try again.',
    });
    */
    console.log(error);
    throw error;
  }
);

const endpoint = async (request: Promise<AxiosResponse<any>>): Promise<ApiResponse<any>> => {
  return request
    .then(response => ({ success: true, data: response.data }))
    .catch(error => {
      const message = error.response?.data?.error || error.message || "Unknown error";
      return { success: false, error: message };
    });
};

export const getUserConsent = (id: string) => endpoint(api.get(`${Resources.Users}/${id}/consent`));
export const createUser = ({ _id, userAgent, userPreviousSite }) => endpoint(api.post(`${Resources.Users}/create`, { _id, userAgent, userPreviousSite }));
export const createSession = ({ userId, userAgent }) => endpoint(api.post(`${Resources.Users}/session`, { userId, userAgent }));
export const getSession = ({ userId, sessionId }) => endpoint(api.get(`${Resources.Users}/${userId}/session/${sessionId}`));
export const getResponses = ({ sessionId }) => endpoint(api.get(`${Resources.Responses}/${ sessionId }`));
export const getScores = ({ attribute1, attribute2 }) => endpoint(api.get(`${Resources.Scores}?attribute1=${attribute1}&attribute2=${attribute2}`));
export const selectCard = ({sessionId, scenarioOrder, cardOrder, consent, consentTimeStamp, chosen}) => endpoint(api.patch(`${Resources.Responses}`, { sessionId, scenarioOrder, cardOrder, consent, consentTimeStamp, chosen }));
export const selectCardFromPair = ({sessionId, scenarioOrder, cardOrder, consent, consentTimeStamp}) => endpoint(api.patch(`${Resources.Responses}/selectPair`, { sessionId, scenarioOrder, cardOrder, consent, consentTimeStamp }));
export const markAnswered = ({sessionId, scenarioOrder, cardOrder, consent, consentTimeStamp}) => endpoint(api.patch(`${Resources.Responses}/markAnswered`, { sessionId, scenarioOrder, cardOrder, consent, consentTimeStamp }));
export const setPresentationTimeStamp = ({sessionId, scenarioOrder, presentationTimeStamp}) => endpoint(api.patch(`${Resources.Responses}/setPresentationTimeStamp`, { sessionId, scenarioOrder, presentationTimeStamp })); 
export const setTaskDescriptionShown = ({sessionId, scenarioOrder}) => endpoint(api.patch(`${Resources.Responses}/setTaskDescriptionShown`, { sessionId, scenarioOrder }));
export const giveConsent = ({userId, consent, consentTimeStamp}) => endpoint(api.patch(`${Resources.Users}/${userId}/consent`, { consent, consentTimeStamp }));
export const feedback = ({userId, sessionId, page, message}) => endpoint(api.post(`${Resources.Feedback}`, { userId, sessionId, page, message }));
export const createSurvey = ({userId, sessionId}) => endpoint(api.post(`${Resources.Surveys}`, { userId, sessionId }));
export const completeSurvey = ({userId, sessionId, answers}) => endpoint(api.patch(`${Resources.Surveys}/complete`, { userId, sessionId, answers }));
export const getSurvey = ({userId, sessionId}) => endpoint(api.get(`${Resources.Surveys}/${userId}/${sessionId}`));
export const setResults = ({userId, sessionId, beforeSurvey, mostSavedClass, leastSavedClass, humansVsSpeciesScore, effectivenessScore, charismaticSpeciesScore, ecologicallyImportantSpeciesScore, economicImpactScore, ecologicalImpactScore }) => endpoint(api.post(`${Resources.Results}`, { userId, sessionId, beforeSurvey, mostSavedClass, leastSavedClass, humansVsSpeciesScore, effectivenessScore, charismaticSpeciesScore, ecologicallyImportantSpeciesScore, economicImpactScore, ecologicalImpactScore }));
export const getRankings = ({userId, sessionId, isSpecies}) => endpoint(api.get(`${Resources.Rankings}/${userId}/${sessionId}?isSpecies=${isSpecies}`));
export const selectRankingFromPair = ({rankingsSessionId, scenarioOrder, cardOrder, consent, consentTimeStamp}) => endpoint(api.patch(`${Resources.Rankings}/selectPair`, { rankingsSessionId, scenarioOrder, cardOrder, consent, consentTimeStamp }));
export const setRankingsPresentationTimeStamp = ({rankingsSessionId, scenarioOrder, presentationTimeStamp}) => endpoint(api.patch(`${Resources.Rankings}/setPresentationTimeStamp`, { rankingsSessionId, scenarioOrder, presentationTimeStamp }));