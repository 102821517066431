export const NUM_FAQS = 5;
export const NUM_CONSENT_PARAGRAPHS = 7;
export const NUM_DILEMMAS = 12;
export const NUM_RATING_OPTIONS = 6;
export const NUM_RATINGS = 10;
export const NUM_INSTRUCTION_STEPS = 3;
export const ESSENTIAL_SURVEY_QUESTIONS = [10, 11, 12, 13, 17, 18, 19];
export const NUM_SURVEY_QUESTIONS_TO_ASK = 12;
export const NUM_SURVEY_QUESTIONS = 22;
export const BUILDING_ODDS = 1;
export const HABITAT_ODDS = 4;
export const SECONDARY_ODDS = 1;
export const DEFAULT_FIRST_ODDS = 4;
export const DEFAULT_SECOND_ODDS = 1;
export const NEAR_ODDS = 1;
export const FAR_ODDS = 1;
export const PROBABILITY_SURVEY_FIRST = 0.5;
export const OTHER_RESULTS_CACHE_LIMIT_MS = 1000 * 60 * 60 * 6;

export enum Locations {
  "NEAR" = "Near",
  "FAR" = "Far",
};

export enum Percentages {
  "ONE_HUNDRED" = "100%",
  "NINETY" = "90%",
  "EIGHTY" = "80%",
  "SEVENTY" = "70%",
  "SIXTY" = "60%",
  "FIFTY" = "50%",
  "FORTY" = "40%",
  "THIRTY" = "30%",
  "TWENTY" = "20%",
  "TEN" = "10%",
};

export enum Effectiveness {
  "ONE_HUNDRED" = 100,
  "TWO_HUNDRED" = 200,
  "THREE_HUNDRED" = 300,
  "FOUR_HUNDRED" = 400,
  "FIVE_HUNDRED" = 500,
  "SIX_HUNDRED" = 600,
  "SEVEN_HUNDRED" = 700,
  "EIGHT_HUNDRED" = 800,
  "NINE_HUNDRED" = 900,
  "ONE_THOUSAND" = 1000,
  "TWO_THOUSAND" = 2000,
  "THREE_THOUSAND" = 3000,
  "FOUR_THOUSAND" = 4000,
  "FIVE_THOUSAND" = 5000,
  "SIX_THOUSAND" = 6000,
  "SEVEN_THOUSAND" = 7000,
  "EIGHT_THOUSAND" = 8000,
  "NINE_THOUSAND" = 9000,
  "TEN_THOUSAND" = 10000,
  "TWENTY_THOUSAND" = 20000,
  "THIRTY_THOUSAND" = 30000,
  "FORTY_THOUSAND" = 40000,
  "FIFTY_THOUSAND" = 50000,
}

export enum Regions {
  "CENTRAL_SOUTH_AMERICA" = "Central/South America",
  "EAST_ASIA" = "East Asia",
  "EASTERN_EUROPE" = "Eastern Europe",
  "NORTH_AFRICA" = "North Africa",
  "NORTH_AMERICA" = "North America",
  "OCEANIA" = "Oceania",
  "SOUTH_SOUTHEAST_ASIA" = "South/Southeast Asia",
  "SUB_SAHARAN_AFRICA" = "Sub-Saharan Africa",
  "WEST_CENTRAL_ASIA" = "West/Central Asia",
  "WESTERN_EUROPE" = "Western Europe",
};

export enum Classes {
  "BIRDS" = "Birds",
  "REPTILES" = "Reptiles",
  "MAMMALS" = "Mammals",
  "INSECTS" = "Insects",
  "ARACHNIDS" = "Arachnids",
  "AMPHIBIANS" = "Amphibians",
  "GASTROPODS" = "Gastropods",
  "CONIFERS" = "Conifers",
  "FLOWERING_PLANTS" = "Flowering Plants",
  "BIVALVES" = "Bivalves",
};

export enum SpeciesRankingCriteria {
  "CHARISMATIC" = "Charismatic",
  "BEUTIFUL" = "Beautiful",
  "INTELLIGENT" = "Intelligent",
  "ENVIRONMENTALLY_IMPORTANT" = "Environmentally Important",
  "DANGEROUS" = "Dangerous",
}

export enum BuildingRankingCriteria {
  "AESTHETIC_VALUE" = "Aesthetic Value",
  "SOCIAL_VALUE" = "Social Value",
  "ECONOMIC_VALUE" = "Economic Value",
  "ENVIRONMENTAL_VALUE" = "Environmental Value",
}

export enum Species {
  "PARROT" = "Parrot",
  "PIGEON" = "Pigeon", 
  "SNAKE" = "Snake", 
  "RAT" = "Rat", 
  "GORILLA" = "Gorilla",
  "PANDA" = "Panda", 
  "TIGER" = "Tiger", 
  "BUMBLEBEE" = "Bumblebee",
  "SCORPION" = "Scorpion", 
  "TEA" = "Tea",
  "PEACOCK" = "Peacock", 
  "EAGLE" = "Eagle", 
  "OWL" = "Owl", 
  "FROG" = "Frog", 
  "GIRAFFE" = "Giraffe", 
  "CAT" = "Cat", 
  "DOG" = "Dog", 
  "SNAIL" = "Snail", 
  "COFFEE" = "Coffee", 
  "CEDAR" = "Cedar",
  "CROW" = "Crow",
  "DUCK" = "Duck",
  "TORTOISE" = "Tortoise",
  "SQUIRREL" = "Squirrel",
  "BAT" = "Bat",
  "MONKEY" = "Monkey",
  "CHEETAH" = "Cheetah",
  "DEER" = "Deer",
  "FOX" = "Fox",
  "WOLF" = "Wolf",
  "RABBIT" = "Rabbit",
  "HORSE" = "Horse",
  "RHINOCEROS" = "Rhinoceros",
  "ELEPHANT" = "Elephant",
  "BUTTERFLY" = "Butterfly",
  "SPIDER" = "Spider",
  "CLAM" = "Clam",
  "OYSTER" = "Oyster",
  "OLIVE" = "Olive",
  "PINE" = "Pine",
}

export enum Buildings {
  "HERITAGE_SITE" = "Heritage Site",
  "NIGHT_CLUB" = "Night Club",
  "RAILWAY_STATION" = "Railway Station",
  "WATER_PURIFICATION_FACILITY" = "Water Purification Facility",
  "NURSING_HOME" = "Nursing Home",
  "HOSPITAL" = "Hospital",
  "MENTAL_HEALTH_UNIT" = "Mental Health Unit",
  "PRISON" = "Prison",
  "BANK" = "Bank",
  "FOOD_AND_BEVERAGE_PRODUCTION" = "Food And Beverage Production",
  "LIBRARY" = "Library",
  "STADIUM" = "Stadium",
  "GOLF_COURSE" = "Golf Course",
  "CAR_PARK" = "Car Park",
  "SOLAR_POWER_STATION" = "Solar Power Station",
  "APARTMENT_COMPLEX" = "Apartment Complex",
  "MULTI_FAITH_BUILDING" = "Multi-faith Building",
  "UNIVERSITY" = "University",
  "MALL" = "Mall",
  "GOVERNMENT_OFFICES" = "Government Offices",
  "CINEMA" = "Cinema",
  "MUSEUM" = "Museum",
  "GYMNASIUM" = "Gymnasium",
  "HIGHWAY" = "Highway",
  "AIRPORT" = "Airport",
  "BUS_STATION" = "Bus Station",
  "HYDROELECTRIC_POWER_STATION" = "Hydroelectric Power Station",
  "NUCLEAR_POWER_STATION" = "Nuclear Power Station",
  "PETROL_STATION" = "Petrol Station",
  "FARMLAND" = "Farmland",
  "SOLID_WASTE_TREATMENT_PLANT" = "Solid Waste Treatment Plant",
  "POSTAL_DELIVERY_OFFICE" = "Postal Delivery Office",
  "TELECOMMUNICATIONS_FACILITY" = "Telecommunications Facility",
  "HOTEL_AND_RESORT" = "Hotel And Resort",
  "CHURCH" = "Church",
  "MOSQUE" = "Mosque",
  "SCHOOL" = "School",
  "POLICE_STATION" = "Police Station",
  "RESTAURANTS_AND_CAFES" = "Restaurants And Cafes",
  "CORPORATE_OFFICES" = "Corporate Offices",
}

export const regionsMap = {
  [Regions.CENTRAL_SOUTH_AMERICA]: { id: 0, label: "Central/South America", image: "/images/regions/central-south-america.svg" },
  [Regions.EAST_ASIA]: { id: 1, label: "East Asia", image: "/images/regions/east-asia.svg" },
  [Regions.EASTERN_EUROPE]: { id: 2, label: "Eastern Europe", image: "/images/regions/eastern-europe.svg" },
  [Regions.NORTH_AFRICA]: { id: 3, label: "North Africa", image: "/images/regions/north-africa.svg" },
  [Regions.NORTH_AMERICA]: { id: 4, label: "North America", image: "/images/regions/north-america.svg" },
  [Regions.OCEANIA]: { id: 5, label: "Oceania", image: "/images/regions/oceania.svg" },
  [Regions.SOUTH_SOUTHEAST_ASIA]: { id: 6, label: "South/Southeast Asia", image: "/images/regions/south-southeast-asia.svg" },
  [Regions.SUB_SAHARAN_AFRICA]: { id: 8, label: "Sub-Saharan Africa", image: "/images/regions/sub-saharan-africa.svg" },
  [Regions.WEST_CENTRAL_ASIA]: { id: 9, label: "West/Central Asia", image: "/images/regions/west-central-asia.svg" },
  [Regions.WESTERN_EUROPE]: { id: 10, label: "Western Europe", image: "/images/regions/western-europe.svg" },
};

export const speciesMap = {
  // Primary
  [Species.PARROT]: { id: 0, label: "Parrot", priority: "primary", image: "/images/species/parrot.svg", class: Classes.BIRDS, charisma: "High", ecologicalImportance: "Medium" },
  [Species.PIGEON]: { id: 1, label: "Pigeon", priority: "primary", image: "/images/species/pigeon.svg", class: Classes.BIRDS, charisma: "Low", ecologicalImportance: "Low" },
  [Species.SNAKE]: { id: 2, label: "Snake", priority: "primary", image: "/images/species/snake.svg", class: Classes.REPTILES, charisma: "Low", ecologicalImportance: "Low" },
  [Species.RAT]: { id: 3, label: "Rat", priority: "primary", image: "/images/species/rat.svg", class: Classes.MAMMALS, charisma: "Low", ecologicalImportance: "Low" },
  [Species.GORILLA]: { id: 4, label: "Gorilla", priority: "primary", image: "/images/species/gorilla.svg", class: Classes.MAMMALS, charisma: "High", ecologicalImportance: "High" },
  [Species.PANDA]: { id: 5, label: "Panda", priority: "primary", image: "/images/species/panda.svg", class: Classes.MAMMALS, charisma: "High", ecologicalImportance: "Low" },
  [Species.TIGER]: { id: 6, label: "Tiger", priority: "primary", image: "/images/species/tiger.svg", class: Classes.MAMMALS, charisma: "High", ecologicalImportance: "High" },
  [Species.BUMBLEBEE]: { id: 7, label: "Bumblebee", priority: "primary", image: "/images/species/bumblebee.svg", class: Classes.INSECTS, charisma: "Medium", ecologicalImportance: "High" },
  [Species.SCORPION]: { id: 8, label: "Scorpion", priority: "primary", image: "/images/species/scorpion.svg", class: Classes.ARACHNIDS, charisma: "Low", ecologicalImportance: "Low" },
  [Species.TEA]: { id: 9, label: "Tea", priority: "primary", image: "/images/species/tea.svg", class: Classes.FLOWERING_PLANTS, charisma: "Low", ecologicalImportance: "Medium" },

  // Secondary
  [Species.PEACOCK]: { id: 10, label: "Peacock", priority: "secondary", image: "/images/species/peacock.svg", class: Classes.BIRDS, charisma: "High", ecologicalImportance: "Medium" },
  [Species.EAGLE]: { id: 11, label: "Eagle", priority: "secondary", image: "/images/species/eagle.svg", class: Classes.BIRDS, charisma: "High", ecologicalImportance: "High" },
  [Species.OWL]: { id: 12, label: "Owl", priority: "secondary", image: "/images/species/owl.svg", class: Classes.BIRDS, charisma: "High", ecologicalImportance: "Medium" },
  [Species.FROG]: { id: 13, label: "Frog", priority: "secondary", image: "/images/species/frog.svg", class: Classes.AMPHIBIANS, charisma: "Low", ecologicalImportance: "High" },
  [Species.GIRAFFE]: { id: 14, label: "Giraffe", priority: "secondary", image: "/images/species/giraffe.svg", class: Classes.MAMMALS, charisma: "Medium", ecologicalImportance: "Medium" },
  [Species.CAT]: { id: 15, label: "Cat", priority: "secondary", image: "/images/species/cat.svg", class: Classes.MAMMALS, charisma: "High", ecologicalImportance: "Medium" },
  [Species.DOG]: { id: 16, label: "Dog", priority: "secondary", image: "/images/species/dog.svg", class: Classes.MAMMALS, charisma: "High", ecologicalImportance: "Medium" },
  [Species.SNAIL]: { id: 17, label: "Snail", priority: "secondary", image: "/images/species/snail.svg", class: Classes.GASTROPODS, charisma: "Low", ecologicalImportance: "Low" },
  [Species.COFFEE]: { id: 18, label: "Coffee", priority: "secondary", image: "/images/species/coffee.svg", class: Classes.FLOWERING_PLANTS, charisma: "Low", ecologicalImportance: "Low" },
  [Species.CEDAR]: { id: 19, label: "Cedar", priority: "secondary", image: "/images/species/cedar.svg", class: Classes.CONIFERS, charisma: "Low", ecologicalImportance: "Low" },

  // Tertiary
  [Species.CROW]: { id: 20, label: "Crow", priority: "tertiary", image: "/images/species/crow.svg", class: Classes.BIRDS, charisma: "Medium", ecologicalImportance: "Low" },
  [Species.DUCK]: { id: 21, label: "Duck", priority: "tertiary", image: "/images/species/duck.svg", class: Classes.BIRDS, charisma: "Medium", ecologicalImportance: "Medium" },
  [Species.TORTOISE]: { id: 22, label: "Tortoise", priority: "tertiary", image: "/images/species/tortoise.svg", class: Classes.REPTILES, charisma: "Medium", ecologicalImportance: "Medium" },
  [Species.SQUIRREL]: { id: 23, label: "Squirrel", priority: "tertiary", image: "/images/species/squirrel.svg", class: Classes.MAMMALS, charisma: "Medium", ecologicalImportance: "Medium" },
  [Species.BAT]: { id: 24, label: "Bat", priority: "tertiary", image: "/images/species/bat.svg", class: Classes.MAMMALS, charisma: "Low", ecologicalImportance: "Medium" },
  [Species.MONKEY]: { id: 25, label: "Monkey", priority: "tertiary", image: "/images/species/monkey.svg", class: Classes.MAMMALS, charisma: "High", ecologicalImportance: "Medium" },
  [Species.CHEETAH]: { id: 26, label: "Cheetah", priority: "tertiary", image: "/images/species/cheetah.svg", class: Classes.MAMMALS, charisma: "High", ecologicalImportance: "Medium" },
  [Species.DEER]: { id: 27, label: "Deer", priority: "tertiary", image: "/images/species/deer.svg", class: Classes.MAMMALS, charisma: "Medium", ecologicalImportance: "Low" },
  [Species.FOX]: { id: 28, label: "Fox", priority: "tertiary", image: "/images/species/fox.svg", class: Classes.MAMMALS, charisma: "High", ecologicalImportance: "Medium" },
  [Species.WOLF]: { id: 29, label: "Wolf", priority: "tertiary", image: "/images/species/wolf.svg", class: Classes.MAMMALS, charisma: "High", ecologicalImportance: "High" },
  [Species.RABBIT]: { id: 30, label: "Rabbit", priority: "tertiary", image: "/images/species/rabbit.svg", class: Classes.MAMMALS, charisma: "Medium", ecologicalImportance: "Low" },
  [Species.HORSE]: { id: 31, label: "Horse", priority: "tertiary", image: "/images/species/horse.svg", class: Classes.MAMMALS, charisma: "High", ecologicalImportance: "Low" },
  [Species.RHINOCEROS]: { id: 32, label: "Rhinoceros", priority: "tertiary", image: "/images/species/rhinoceros.svg", class: Classes.MAMMALS, charisma: "Medium", ecologicalImportance: "High" },
  [Species.ELEPHANT]: { id: 33, label: "Elephant", priority: "tertiary", image: "/images/species/elephant.svg", class: Classes.MAMMALS, charisma: "High", ecologicalImportance: "High" },
  [Species.BUTTERFLY]: { id: 34, label: "Butterfly", priority: "tertiary", image: "/images/species/butterfly.svg", class: Classes.INSECTS, charisma: "Medium", ecologicalImportance: "High" },
  [Species.SPIDER]: { id: 35, label: "Spider", priority: "tertiary", image: "/images/species/spider.svg", class: Classes.ARACHNIDS, charisma: "Low", ecologicalImportance: "High" },
  [Species.CLAM]: { id: 36, label: "Clam", priority: "tertiary", image: "/images/species/clam.svg", class: Classes.BIVALVES, charisma: "Low", ecologicalImportance: "Medium" },
  [Species.OYSTER]: { id: 37, label: "Oyster", priority: "tertiary", image: "/images/species/oyster.svg", class: Classes.BIVALVES, charisma: "Low", ecologicalImportance: "Low" },
  [Species.OLIVE]: { id: 38, label: "Olive", priority: "tertiary", image: "/images/species/olive.svg", class: Classes.FLOWERING_PLANTS, charisma: "Low", ecologicalImportance: "Low" },
  [Species.PINE]: { id: 39, label: "Pine", priority: "tertiary", image: "/images/species/pine.svg", class: Classes.CONIFERS, charisma: "Low", ecologicalImportance: "Medium" },
};

export const buildingsMap = {
  // Primary
  [Buildings.HERITAGE_SITE]: { id: 0, label: "Heritage Site", priority: "primary", image: "/images/buildings/heritage-site.svg", economicImpact: "Low", ecologicalImpact: "Low" },
  [Buildings.NIGHT_CLUB]: { id: 1, label: "Night Club", priority: "primary", image: "/images/buildings/night-club.svg", economicImpact: "Low", ecologicalImpact: "Low" },
  [Buildings.RAILWAY_STATION]: { id: 2, label: "Railway Station", priority: "primary", image: "/images/buildings/railway-station.svg", economicImpact: "High", ecologicalImpact: "Medium" },
  [Buildings.WATER_PURIFICATION_FACILITY]: { id: 3, label: "Water Purification Facility", priority: "primary", image: "/images/buildings/water-purification-facility.svg", economicImpact: "Medium", ecologicalImpact: "High" },
  [Buildings.NURSING_HOME]: { id: 4, label: "Nursing Home", priority: "primary", image: "/images/buildings/nursing-home.svg", economicImpact: "Medium", ecologicalImpact: "Medium" },
  [Buildings.HOSPITAL]: { id: 5, label: "Hospital", priority: "primary", image: "/images/buildings/hospital.svg", economicImpact: "High", ecologicalImpact: "Medium" },
  [Buildings.MENTAL_HEALTH_UNIT]: { id: 6, label: "Mental Health Unit", priority: "primary", image: "/images/buildings/mental-health-unit.svg", economicImpact: "Medium", ecologicalImpact: "Medium" },
  [Buildings.PRISON]: { id: 7, label: "Prison", priority: "primary", image: "/images/buildings/prison.svg", economicImpact: "Low", ecologicalImpact: "Low" },
  [Buildings.BANK]: { id: 8, label: "Bank", priority: "primary", image: "/images/buildings/bank.svg", economicImpact: "High", ecologicalImpact: "Medium" },
  [Buildings.FOOD_AND_BEVERAGE_PRODUCTION]: { id: 9, label: "Food And Beverage Production", priority: "primary", image: "/images/buildings/food-and-beverage-production.svg", economicImpact: "High", ecologicalImpact: "Medium" },

  // Secondary
  [Buildings.LIBRARY]: { id: 10, label: "Library", priority: "secondary", image: "/images/buildings/library.svg", economicImpact: "Medium", ecologicalImpact: "Medium" },
  [Buildings.STADIUM]: { id: 11, label: "Stadium", priority: "secondary", image: "/images/buildings/stadium.svg", economicImpact: "Medium", ecologicalImpact: "Medium" },
  [Buildings.GOLF_COURSE]: { id: 12, label: "Golf Course", priority: "secondary", image: "/images/buildings/golf-course.svg", economicImpact: "Low", ecologicalImpact: "Low" },
  [Buildings.CAR_PARK]: { id: 13, label: "Car Park", priority: "secondary", image: "/images/buildings/car-park.svg", economicImpact: "High", ecologicalImpact: "Medium" },
  [Buildings.SOLAR_POWER_STATION]: { id: 14, label: "Solar Power Station", priority: "secondary", image: "/images/buildings/solar-power-station.svg", economicImpact: "High", ecologicalImpact: "High" },
  [Buildings.APARTMENT_COMPLEX]: { id: 15, label: "Apartment Complex", priority: "secondary", image: "/images/buildings/apartment-complex.svg", economicImpact: "Medium", ecologicalImpact: "Medium" },
  [Buildings.MULTI_FAITH_BUILDING]: { id: 16, label: "Multi-faith Building", priority: "secondary", image: "/images/buildings/multi-faith-building.svg", economicImpact: "Low", ecologicalImpact: "Low" },
  [Buildings.UNIVERSITY]: { id: 17, label: "University", priority: "secondary", image: "/images/buildings/university.svg", economicImpact: "High", ecologicalImpact: "Medium" },
  [Buildings.MALL]: { id: 18, label: "Mall", priority: "secondary", image: "/images/buildings/mall.svg", economicImpact: "High", ecologicalImpact: "Medium" },
  [Buildings.GOVERNMENT_OFFICES]: { id: 19, label: "Government Offices", priority: "secondary", image: "/images/buildings/government-offices.svg", economicImpact: "High", ecologicalImpact: "Medium" },

  // Tertiary
  [Buildings.CINEMA]: { id: 20, label: "Cinema", priority: "tertiary", image: "/images/buildings/cinema.svg", economicImpact: "Low", ecologicalImpact: "Low" },
  [Buildings.MUSEUM]: { id: 21, label: "Museum", priority: "tertiary", image: "/images/buildings/museum.svg", economicImpact: "Medium", ecologicalImpact: "Medium" },
  [Buildings.GYMNASIUM]: { id: 22, label: "Gymnasium", priority: "tertiary", image: "/images/buildings/gymnasium.svg", economicImpact: "Medium", ecologicalImpact: "Medium" },
  [Buildings.HIGHWAY]: { id: 23, label: "Highway", priority: "tertiary", image: "/images/buildings/highway.svg", economicImpact: "Medium", ecologicalImpact: "Medium" },
  [Buildings.AIRPORT]: { id: 24, label: "Airport", priority: "tertiary", image: "/images/buildings/airport.svg", economicImpact: "High", ecologicalImpact: "Medium" },
  [Buildings.BUS_STATION]: { id: 25, label: "Bus Station", priority: "tertiary", image: "/images/buildings/bus-station.svg", economicImpact: "High", ecologicalImpact: "Medium" },
  [Buildings.HYDROELECTRIC_POWER_STATION]: { id: 26, label: "Hydroelectric Power Station", priority: "tertiary", image: "/images/buildings/hydroelectric-power-station.svg", economicImpact: "High", ecologicalImpact: "High" },
  [Buildings.NUCLEAR_POWER_STATION]: { id: 27, label: "Nuclear Power Station", priority: "tertiary", image: "/images/buildings/nuclear-power-station.svg", economicImpact: "High", ecologicalImpact: "High" },
  [Buildings.PETROL_STATION]: { id: 28, label: "Petrol Station", priority: "tertiary", image: "/images/buildings/petrol-station.svg", economicImpact: "Low", ecologicalImpact: "Low" },
  [Buildings.FARMLAND]: { id: 29, label: "Farmland", priority: "tertiary", image: "/images/buildings/farmland.svg", economicImpact: "High", ecologicalImpact: "High" },
  [Buildings.SOLID_WASTE_TREATMENT_PLANT]: { id: 30, label: "Solid Waste Treatment Plant", priority: "tertiary", image: "/images/buildings/solid-waste-treatment-plant.svg", economicImpact: "Medium", ecologicalImpact: "High" },
  [Buildings.POSTAL_DELIVERY_OFFICE]: { id: 31, label: "Postal Delivery Office", priority: "tertiary", image: "/images/buildings/postal-delivery-office.svg", economicImpact: "Medium", ecologicalImpact: "Medium" },
  [Buildings.TELECOMMUNICATIONS_FACILITY]: { id: 32, label: "Telecommunications Facility", priority: "tertiary", image: "/images/buildings/telecommunications-facility.svg", economicImpact: "Medium", ecologicalImpact: "Medium" },
  [Buildings.HOTEL_AND_RESORT]: { id: 33, label: "Hotel And Resort", priority: "tertiary", image: "/images/buildings/hotel-and-resort.svg", economicImpact: "Medium", ecologicalImpact: "Low" },
  [Buildings.CHURCH]: { id: 34, label: "Church", priority: "tertiary", image: "/images/buildings/church.svg", economicImpact: "Low", ecologicalImpact: "Low" },
  [Buildings.MOSQUE]: { id: 35, label: "Mosque", priority: "tertiary", image: "/images/buildings/mosque.svg", economicImpact: "Low", ecologicalImpact: "Low" },
  [Buildings.SCHOOL]: { id: 36, label: "School", priority: "tertiary", image: "/images/buildings/school.svg", economicImpact: "Medium", ecologicalImpact: "Medium" },
  [Buildings.POLICE_STATION]: { id: 37, label: "Police Station", priority: "tertiary", image: "/images/buildings/police-station.svg", economicImpact: "Medium", ecologicalImpact: "Medium" },
  [Buildings.RESTAURANTS_AND_CAFES]: { id: 38, label: "Restaurants And Cafes", priority: "tertiary", image: "/images/buildings/restaurants-and-cafes.svg", economicImpact: "High", ecologicalImpact: "Medium" },
  [Buildings.CORPORATE_OFFICES]: { id: 39, label: "Corporate Offices", priority: "tertiary", image: "/images/buildings/corporate-offices.svg", economicImpact: "High", ecologicalImpact: "Medium" },
};

export const categoryMap: Record<string, { count: number, species: Species[], buildings: Buildings[] }> = {
  primary: {
    count: 6, 
    species: [
      Species.PARROT, Species.PIGEON, Species.SNAKE, Species.RAT, 
      Species.GORILLA, Species.PANDA, Species.TIGER, Species.BUMBLEBEE, 
      Species.SCORPION, Species.TEA
    ], 
    buildings: [
      Buildings.HERITAGE_SITE, Buildings.NIGHT_CLUB, Buildings.RAILWAY_STATION, 
      Buildings.WATER_PURIFICATION_FACILITY, Buildings.NURSING_HOME, 
      Buildings.HOSPITAL, Buildings.MENTAL_HEALTH_UNIT, Buildings.PRISON, 
      Buildings.BANK, Buildings.FOOD_AND_BEVERAGE_PRODUCTION
    ],
  },
  secondary: {
    count: 3, 
    species: [
      Species.PEACOCK, Species.EAGLE, Species.OWL, Species.FROG, 
      Species.GIRAFFE, Species.CAT, Species.DOG, Species.SNAIL, 
      Species.COFFEE, Species.CEDAR
    ], 
    buildings: [
      Buildings.LIBRARY, Buildings.STADIUM, Buildings.GOLF_COURSE, 
      Buildings.CAR_PARK, Buildings.SOLAR_POWER_STATION, 
      Buildings.APARTMENT_COMPLEX, Buildings.MULTI_FAITH_BUILDING, 
      Buildings.UNIVERSITY, Buildings.MALL, Buildings.GOVERNMENT_OFFICES
    ],
  },
  tertiary: {
    count: 3, 
    species: [
      Species.CROW, Species.DUCK, Species.TORTOISE, Species.SQUIRREL, 
      Species.BAT, Species.MONKEY, Species.CHEETAH, Species.DEER, 
      Species.FOX, Species.WOLF, Species.RABBIT, Species.HORSE, 
      Species.RHINOCEROS, Species.ELEPHANT, Species.BUTTERFLY, 
      Species.SPIDER, Species.CLAM, Species.OYSTER, Species.OLIVE, 
      Species.PINE
    ], 
    buildings: [
      Buildings.CINEMA, Buildings.MUSEUM, Buildings.GYMNASIUM, 
      Buildings.HIGHWAY, Buildings.AIRPORT, Buildings.BUS_STATION, 
      Buildings.HYDROELECTRIC_POWER_STATION, Buildings.NUCLEAR_POWER_STATION, 
      Buildings.PETROL_STATION, Buildings.FARMLAND, 
      Buildings.SOLID_WASTE_TREATMENT_PLANT, Buildings.POSTAL_DELIVERY_OFFICE, 
      Buildings.TELECOMMUNICATIONS_FACILITY, Buildings.HOTEL_AND_RESORT, 
      Buildings.CHURCH, Buildings.MOSQUE, Buildings.SCHOOL, 
      Buildings.POLICE_STATION, Buildings.RESTAURANTS_AND_CAFES, 
      Buildings.CORPORATE_OFFICES
    ],
  },
};

const totalCategoryCount = Object.values(categoryMap).reduce((sum, category) => sum + category.count, 0);
if (totalCategoryCount !== NUM_DILEMMAS) {
  throw new Error(`The sum of category counts (${totalCategoryCount}) does not equal NUM_DILEMMAS (${NUM_DILEMMAS})`);
}

export const percentagesMap = {
  // Odds: 15
  [Percentages.ONE_HUNDRED]: { label: "100%", value: 100, odds: 6 },
  [Percentages.NINETY]: { label: "90%", value: 90, odds: 3 },
  [Percentages.EIGHTY]: { label: "80%", value: 80, odds: 3 },
  [Percentages.SEVENTY]: { label: "70%", value: 70, odds: 2 },
  [Percentages.SIXTY]: { label: "60%", value: 60, odds: 2 },
  [Percentages.FIFTY]: { label: "50%", value: 50, odds: 1 },
  [Percentages.FORTY]: { label: "40%", value: 40, odds: 1 },
  [Percentages.THIRTY]: { label: "30%", value: 30, odds: 1 },
  [Percentages.TWENTY]: { label: "20%", value: 20, odds: 1 },
  [Percentages.TEN]: { label: "10%", value: 10, odds: 1 },
};

export const effectivenessMap = {
  // Odds: 6
  [Effectiveness.ONE_HUNDRED]: { label: "100", value: 100, odds: 6 },
  [Effectiveness.FIVE_HUNDRED]: { label: "500", value: 500, odds: 6 },
  [Effectiveness.ONE_THOUSAND]: { label: "1000", value: 1000, odds: 6 },
  [Effectiveness.FIVE_THOUSAND]: { label: "5000", value: 5000, odds: 6 },
  [Effectiveness.TEN_THOUSAND]: { label: "10000", value: 10000, odds: 6 },
  [Effectiveness.FIFTY_THOUSAND]: { label: "50000", value: 50000, odds: 6 },

  // Odds: 3
  [Effectiveness.TWO_HUNDRED]: { label: "200", value: 200, odds: 3 },
  [Effectiveness.THREE_HUNDRED]: { label: "300", value: 300, odds: 3 },
  [Effectiveness.FOUR_HUNDRED]: { label: "400", value: 400, odds: 3 },
  [Effectiveness.SIX_HUNDRED]: { label: "600", value: 600, odds: 3 },
  [Effectiveness.SEVEN_HUNDRED]: { label: "700", value: 700, odds: 3 },
  [Effectiveness.EIGHT_HUNDRED]: { label: "800", value: 800, odds: 3 },
  [Effectiveness.NINE_HUNDRED]: { label: "900", value: 900, odds: 3 },

  // Odds: 2
  [Effectiveness.TWO_THOUSAND]: { label: "2000", value: 2000, odds: 2 },
  [Effectiveness.THREE_THOUSAND]: { label: "3000", value: 3000, odds: 2 },
  [Effectiveness.FOUR_THOUSAND]: { label: "4000", value: 4000, odds: 2 },
  [Effectiveness.SIX_THOUSAND]: { label: "6000", value: 6000, odds: 2 },
  [Effectiveness.SEVEN_THOUSAND]: { label: "7000", value: 7000, odds: 2 },
  [Effectiveness.EIGHT_THOUSAND]: { label: "8000", value: 8000, odds: 2 },
  [Effectiveness.NINE_THOUSAND]: { label: "9000", value: 9000, odds: 2 },

  // Odds: 1
  [Effectiveness.TWENTY_THOUSAND]: { label: "20000", value: 20000, odds: 1 },
  [Effectiveness.THIRTY_THOUSAND]: { label: "30000", value: 30000, odds: 1 },
  [Effectiveness.FORTY_THOUSAND]: { label: "40000", value: 40000, odds: 1 },
};

export const speciesClassMap = {
  [Classes.BIRDS]: { label: "Birds", image: "/images/speciesClasses/birds.svg" },
  [Classes.REPTILES]: { label: "Reptiles", image: "/images/speciesClasses/reptiles.svg" },
  [Classes.MAMMALS]: { label: "Mammals", image: "/images/speciesClasses/mammals.svg" },
  [Classes.INSECTS]: { label: "Insects", image: "/images/speciesClasses/insects.svg" },
  [Classes.ARACHNIDS]: { label: "Arachnids", image: "/images/speciesClasses/arachnids.svg" },
  [Classes.AMPHIBIANS]: { label: "Amphibians", image: "/images/speciesClasses/amphibians.svg" },
  [Classes.GASTROPODS]: { label: "Gastropods", image: "/images/speciesClasses/gastropods.svg" },
  [Classes.BIVALVES]: { label: "Bivalves", image: "/images/speciesClasses/bivalves.svg" },
  [Classes.FLOWERING_PLANTS]: { label: "Flowering Plants", image: "/images/speciesClasses/plants.svg" },
  [Classes.CONIFERS]: { label: "Conifers", image: "/images/speciesClasses/plants.svg" },
};

export const COUNTRIES = [
  { label: 'Afghanistan', value: 'AF' },
  { label: 'Aland Islands', value: 'AX' },
  { label: 'Albania', value: 'AL' },
  { label: 'Algeria', value: 'DZ' },
  { label: 'American Samoa', value: 'AS' },
  { label: 'Andorra', value: 'AD' },
  { label: 'Angola', value: 'AO' },
  { label: 'Anguilla', value: 'AI' },
  { label: 'Antarctica', value: 'AQ' },
  { label: 'Antigua and Barbuda', value: 'AG' },
  { label: 'Argentina', value: 'AR' },
  { label: 'Armenia', value: 'AM' },
  { label: 'Aruba', value: 'AW' },
  { label: 'Australia', value: 'AU' },
  { label: 'Austria', value: 'AT' },
  { label: 'Azerbaijan', value: 'AZ' },
  { label: 'Bahamas', value: 'BS' },
  { label: 'Bahrain', value: 'BH' },
  { label: 'Bangladesh', value: 'BD' },
  { label: 'Barbados', value: 'BB' },
  { label: 'Belarus', value: 'BY' },
  { label: 'Belgium', value: 'BE' },
  { label: 'Belize', value: 'BZ' },
  { label: 'Benin', value: 'BJ' },
  { label: 'Bermuda', value: 'BM' },
  { label: 'Bhutan', value: 'BT' },
  { label: 'Bolivia', value: 'BO' },
  { label: 'Bosnia and Herzegovina', value: 'BA' },
  { label: 'Botswana', value: 'BW' },
  { label: 'Bouvet Island', value: 'BV' },
  { label: 'Brazil', value: 'BR' },
  { label: 'British Indian Ocean Territory', value: 'IO' },
  { label: 'Brunei Darussalam', value: 'BN' },
  { label: 'Bulgaria', value: 'BG' },
  { label: 'Burkina Faso', value: 'BF' },
  { label: 'Burundi', value: 'BI' },
  { label: 'Cambodia', value: 'KH' },
  { label: 'Cameroon', value: 'CM' },
  { label: 'Canada', value: 'CA' },
  { label: 'Cape Verde', value: 'CV' },
  { label: 'Cayman Islands', value: 'KY' },
  { label: 'Central African Republic', value: 'CF' },
  { label: 'Chad', value: 'TD' },
  { label: 'Chile', value: 'CL' },
  { label: 'China', value: 'CN' },
  { label: 'Christmas Island', value: 'CX' },
  { label: 'Cocos (Keeling) Islands', value: 'CC' },
  { label: 'Colombia', value: 'CO' },
  { label: 'Comoros', value: 'KM' },
  { label: 'Congo', value: 'CG' },
  { label: 'Congo, The Democratic Republic of the', value: 'CD' },
  { label: 'Cook Islands', value: 'CK' },
  { label: 'Costa Rica', value: 'CR' },
  { label: "Cote D'Ivoire", value: 'CI' },
  { label: 'Croatia', value: 'HR' },
  { label: 'Cuba', value: 'CU' },
  { label: 'Cyprus', value: 'CY' },
  { label: 'Czech Republic', value: 'CZ' },
  { label: 'Denmark', value: 'DK' },
  { label: 'Djibouti', value: 'DJ' },
  { label: 'Dominica', value: 'DM' },
  { label: 'Dominican Republic', value: 'DO' },
  { label: 'Ecuador', value: 'EC' },
  { label: 'Egypt', value: 'EG' },
  { label: 'El Salvador', value: 'SV' },
  { label: 'Equatorial Guinea', value: 'GQ' },
  { label: 'Eritrea', value: 'ER' },
  { label: 'Estonia', value: 'EE' },
  { label: 'Ethiopia', value: 'ET' },
  { label: 'Falkland Islands (Malvinas)', value: 'FK' },
  { label: 'Faroe Islands', value: 'FO' },
  { label: 'Fiji', value: 'FJ' },
  { label: 'Finland', value: 'FI' },
  { label: 'France', value: 'FR' },
  { label: 'French Guiana', value: 'GF' },
  { label: 'French Polynesia', value: 'PF' },
  { label: 'French Southern Territories', value: 'TF' },
  { label: 'Gabon', value: 'GA' },
  { label: 'Gambia', value: 'GM' },
  { label: 'Georgia', value: 'GE' },
  { label: 'Germany', value: 'DE' },
  { label: 'Ghana', value: 'GH' },
  { label: 'Gibraltar', value: 'GI' },
  { label: 'Greece', value: 'GR' },
  { label: 'Greenland', value: 'GL' },
  { label: 'Grenada', value: 'GD' },
  { label: 'Guadeloupe', value: 'GP' },
  { label: 'Guam', value: 'GU' },
  { label: 'Guatemala', value: 'GT' },
  { label: 'Guernsey', value: 'GG' },
  { label: 'Guinea', value: 'GN' },
  { label: 'Guinea-Bissau', value: 'GW' },
  { label: 'Guyana', value: 'GY' },
  { label: 'Haiti', value: 'HT' },
  { label: 'Heard Island and Mcdonald Islands', value: 'HM' },
  { label: 'Holy See (Vatican City State)', value: 'VA' },
  { label: 'Honduras', value: 'HN' },
  { label: 'Hong Kong', value: 'HK' },
  { label: 'Hungary', value: 'HU' },
  { label: 'Iceland', value: 'IS' },
  { label: 'India', value: 'IN' },
  { label: 'Indonesia', value: 'ID' },
  { label: 'Iran, Islamic Republic Of', value: 'IR' },
  { label: 'Iraq', value: 'IQ' },
  { label: 'Ireland', value: 'IE' },
  { label: 'Isle of Man', value: 'IM' },
  { label: 'Israel', value: 'IL' },
  { label: 'Italy', value: 'IT' },
  { label: 'Jamaica', value: 'JM' },
  { label: 'Japan', value: 'JP' },
  { label: 'Jersey', value: 'JE' },
  { label: 'Jordan', value: 'JO' },
  { label: 'Kazakhstan', value: 'KZ' },
  { label: 'Kenya', value: 'KE' },
  { label: 'Kiribati', value: 'KI' },
  { label: "Korea, Democratic People'S Republic of", value: 'KP' },
  { label: 'Korea, Republic of', value: 'KR' },
  { label: 'Kuwait', value: 'KW' },
  { label: 'Kyrgyzstan', value: 'KG' },
  { label: "Lao People's Democratic Republic", value: 'LA' },
  { label: 'Latvia', value: 'LV' },
  { label: 'Lebanon', value: 'LB' },
  { label: 'Lesotho', value: 'LS' },
  { label: 'Liberia', value: 'LR' },
  { label: 'Libyan Arab Jamahiriya', value: 'LY' },
  { label: 'Liechtenstein', value: 'LI' },
  { label: 'Lithuania', value: 'LT' },
  { label: 'Luxembourg', value: 'LU' },
  { label: 'Macao', value: 'MO' },
  { label: 'Macedonia, The Former Yugoslav Republic of', value: 'MK' },
  { label: 'Madagascar', value: 'MG' },
  { label: 'Malawi', value: 'MW' },
  { label: 'Malaysia', value: 'MY' },
  { label: 'Maldives', value: 'MV' },
  { label: 'Mali', value: 'ML' },
  { label: 'Malta', value: 'MT' },
  { label: 'Marshall Islands', value: 'MH' },
  { label: 'Martinique', value: 'MQ' },
  { label: 'Mauritania', value: 'MR' },
  { label: 'Mauritius', value: 'MU' },
  { label: 'Mayotte', value: 'YT' },
  { label: 'Mexico', value: 'MX' },
  { label: 'Micronesia, Federated States of', value: 'FM' },
  { label: 'Moldova, Republic of', value: 'MD' },
  { label: 'Monaco', value: 'MC' },
  { label: 'Mongolia', value: 'MN' },
  { label: 'Montserrat', value: 'MS' },
  { label: 'Morocco', value: 'MA' },
  { label: 'Mozambique', value: 'MZ' },
  { label: 'Myanmar', value: 'MM' },
  { label: 'Namibia', value: 'NA' },
  { label: 'Nauru', value: 'NR' },
  { label: 'Nepal', value: 'NP' },
  { label: 'Netherlands', value: 'NL' },
  { label: 'Netherlands Antilles', value: 'AN' },
  { label: 'New Caledonia', value: 'NC' },
  { label: 'New Zealand', value: 'NZ' },
  { label: 'Nicaragua', value: 'NI' },
  { label: 'Niger', value: 'NE' },
  { label: 'Nigeria', value: 'NG' },
  { label: 'Niue', value: 'NU' },
  { label: 'Norfolk Island', value: 'NF' },
  { label: 'Northern Mariana Islands', value: 'MP' },
  { label: 'Norway', value: 'NO' },
  { label: 'Oman', value: 'OM' },
  { label: 'Pakistan', value: 'PK' },
  { label: 'Palau', value: 'PW' },
  { label: 'Palestine', value: 'PS' },
  { label: 'Panama', value: 'PA' },
  { label: 'Papua New Guinea', value: 'PG' },
  { label: 'Paraguay', value: 'PY' },
  { label: 'Peru', value: 'PE' },
  { label: 'Philippines', value: 'PH' },
  { label: 'Pitcairn', value: 'PN' },
  { label: 'Poland', value: 'PL' },
  { label: 'Portugal', value: 'PT' },
  { label: 'Puerto Rico', value: 'PR' },
  { label: 'Qatar', value: 'QA' },
  { label: 'Reunion', value: 'RE' },
  { label: 'Romania', value: 'RO' },
  { label: 'Russian Federation', value: 'RU' },
  { label: 'Rwanda', value: 'RW' },
  { label: 'Saint Helena', value: 'SH' },
  { label: 'Saint Kitts and Nevis', value: 'KN' },
  { label: 'Saint Lucia', value: 'LC' },
  { label: 'Saint Pierre and Miquelon', value: 'PM' },
  { label: 'Saint Vincent and the Grenadines', value: 'VC' },
  { label: 'Samoa', value: 'WS' },
  { label: 'San Marino', value: 'SM' },
  { label: 'Sao Tome and Principe', value: 'ST' },
  { label: 'Saudi Arabia', value: 'SA' },
  { label: 'Senegal', value: 'SN' },
  { label: 'Serbia and Montenegro', value: 'CS' },
  { label: 'Seychelles', value: 'SC' },
  { label: 'Sierra Leone', value: 'SL' },
  { label: 'Singapore', value: 'SG' },
  { label: 'Slovakia', value: 'SK' },
  { label: 'Slovenia', value: 'SI' },
  { label: 'Solomon Islands', value: 'SB' },
  { label: 'Somalia', value: 'SO' },
  { label: 'South Africa', value: 'ZA' },
  { label: 'South Georgia and the South Sandwich Islands', value: 'GS' },
  { label: 'Spain', value: 'ES' },
  { label: 'Sri Lanka', value: 'LK' },
  { label: 'Sudan', value: 'SD' },
  { label: 'Surilabel', value: 'SR' },
  { label: 'Svalbard and Jan Mayen', value: 'SJ' },
  { label: 'Swaziland', value: 'SZ' },
  { label: 'Sweden', value: 'SE' },
  { label: 'Switzerland', value: 'CH' },
  { label: 'Syrian Arab Republic', value: 'SY' },
  { label: 'Taiwan, Province of China', value: 'TW' },
  { label: 'Tajikistan', value: 'TJ' },
  { label: 'Tanzania, United Republic of', value: 'TZ' },
  { label: 'Thailand', value: 'TH' },
  { label: 'Timor-Leste', value: 'TL' },
  { label: 'Togo', value: 'TG' },
  { label: 'Tokelau', value: 'TK' },
  { label: 'Tonga', value: 'TO' },
  { label: 'Trinidad and Tobago', value: 'TT' },
  { label: 'Tunisia', value: 'TN' },
  { label: 'Turkey', value: 'TR' },
  { label: 'Turkmenistan', value: 'TM' },
  { label: 'Turks and Caicos Islands', value: 'TC' },
  { label: 'Tuvalu', value: 'TV' },
  { label: 'Uganda', value: 'UG' },
  { label: 'Ukraine', value: 'UA' },
  { label: 'United Arab Emirates', value: 'AE' },
  { label: 'United Kingdom', value: 'GB' },
  { label: 'United States', value: 'US' },
  { label: 'United States Minor Outlying Islands', value: 'UM' },
  { label: 'Uruguay', value: 'UY' },
  { label: 'Uzbekistan', value: 'UZ' },
  { label: 'Vanuatu', value: 'VU' },
  { label: 'Venezuela', value: 'VE' },
  { label: 'Vietnam', value: 'VN' },
  { label: 'Virgin Islands, British', value: 'VG' },
  { label: 'Virgin Islands, U.S.', value: 'VI' },
  { label: 'Wallis and Futuna', value: 'WF' },
  { label: 'Western Sahara', value: 'EH' },
  { label: 'Yemen', value: 'YE' },
  { label: 'Zambia', value: 'ZM' },
  { label: 'Zimbabwe', value: 'ZW' },
]

export const countryMap: { [key: string]: Regions } = {
  // Central/South America
  'AI': Regions.CENTRAL_SOUTH_AMERICA,
  'AQ': Regions.CENTRAL_SOUTH_AMERICA,
  'AG': Regions.CENTRAL_SOUTH_AMERICA,
  'AR': Regions.CENTRAL_SOUTH_AMERICA,
  'AW': Regions.CENTRAL_SOUTH_AMERICA,
  'BS': Regions.CENTRAL_SOUTH_AMERICA,
  'BB': Regions.CENTRAL_SOUTH_AMERICA,
  'BZ': Regions.CENTRAL_SOUTH_AMERICA,
  'BM': Regions.CENTRAL_SOUTH_AMERICA,
  'BO': Regions.CENTRAL_SOUTH_AMERICA,
  'BR': Regions.CENTRAL_SOUTH_AMERICA,
  'CL': Regions.CENTRAL_SOUTH_AMERICA,
  'CO': Regions.CENTRAL_SOUTH_AMERICA,
  'CR': Regions.CENTRAL_SOUTH_AMERICA,
  'CU': Regions.CENTRAL_SOUTH_AMERICA,
  'DM': Regions.CENTRAL_SOUTH_AMERICA,
  'DO': Regions.CENTRAL_SOUTH_AMERICA,
  'EC': Regions.CENTRAL_SOUTH_AMERICA,
  'SV': Regions.CENTRAL_SOUTH_AMERICA,
  'FK': Regions.CENTRAL_SOUTH_AMERICA,
  'GF': Regions.CENTRAL_SOUTH_AMERICA,
  'GD': Regions.CENTRAL_SOUTH_AMERICA,
  'GP': Regions.CENTRAL_SOUTH_AMERICA,
  'GT': Regions.CENTRAL_SOUTH_AMERICA,
  'GY': Regions.CENTRAL_SOUTH_AMERICA,
  'HT': Regions.CENTRAL_SOUTH_AMERICA,
  'HN': Regions.CENTRAL_SOUTH_AMERICA,
  'JM': Regions.CENTRAL_SOUTH_AMERICA,
  'MQ': Regions.CENTRAL_SOUTH_AMERICA,
  'MS': Regions.CENTRAL_SOUTH_AMERICA,
  'NI': Regions.CENTRAL_SOUTH_AMERICA,
  'PA': Regions.CENTRAL_SOUTH_AMERICA,
  'PY': Regions.CENTRAL_SOUTH_AMERICA,
  'PE': Regions.CENTRAL_SOUTH_AMERICA,
  'PR': Regions.CENTRAL_SOUTH_AMERICA,
  'BL': Regions.CENTRAL_SOUTH_AMERICA,
  'KN': Regions.CENTRAL_SOUTH_AMERICA,
  'LC': Regions.CENTRAL_SOUTH_AMERICA,
  'MF': Regions.CENTRAL_SOUTH_AMERICA,
  'PM': Regions.CENTRAL_SOUTH_AMERICA,
  'VC': Regions.CENTRAL_SOUTH_AMERICA,
  'SR': Regions.CENTRAL_SOUTH_AMERICA,
  'TT': Regions.CENTRAL_SOUTH_AMERICA,
  'TC': Regions.CENTRAL_SOUTH_AMERICA,
  'UY': Regions.CENTRAL_SOUTH_AMERICA,
  'VE': Regions.CENTRAL_SOUTH_AMERICA,
  'VG': Regions.CENTRAL_SOUTH_AMERICA,
  'VI': Regions.CENTRAL_SOUTH_AMERICA,

  // East Asia
  'CN': Regions.EAST_ASIA,
  'HK': Regions.EAST_ASIA,
  'JP': Regions.EAST_ASIA,
  'KP': Regions.EAST_ASIA,
  'KR': Regions.EAST_ASIA,
  'MO': Regions.EAST_ASIA,
  'MN': Regions.EAST_ASIA,
  'TW': Regions.EAST_ASIA,

  // Eastern Europe
  'AL': Regions.EASTERN_EUROPE,
  'BY': Regions.EASTERN_EUROPE,
  'BA': Regions.EASTERN_EUROPE,
  'BG': Regions.EASTERN_EUROPE,
  'HR': Regions.EASTERN_EUROPE,
  'CZ': Regions.EASTERN_EUROPE,
  'EE': Regions.EASTERN_EUROPE,
  'GE': Regions.EASTERN_EUROPE,
  'HU': Regions.EASTERN_EUROPE,
  'KZ': Regions.EASTERN_EUROPE,
  'LV': Regions.EASTERN_EUROPE,
  'LT': Regions.EASTERN_EUROPE,
  'MD': Regions.EASTERN_EUROPE,
  'ME': Regions.EASTERN_EUROPE,
  'MK': Regions.EASTERN_EUROPE,
  'PL': Regions.EASTERN_EUROPE,
  'RO': Regions.EASTERN_EUROPE,
  'RU': Regions.EASTERN_EUROPE,
  'RS': Regions.EASTERN_EUROPE,
  'SK': Regions.EASTERN_EUROPE,
  'SI': Regions.EASTERN_EUROPE,
  'UA': Regions.EASTERN_EUROPE,

  // North Africa
  'DZ': Regions.NORTH_AFRICA,
  'EG': Regions.NORTH_AFRICA,
  'LY': Regions.NORTH_AFRICA,
  'MA': Regions.NORTH_AFRICA,
  'SD': Regions.NORTH_AFRICA,
  'TN': Regions.NORTH_AFRICA,
  'EH': Regions.NORTH_AFRICA,

  // North America
  'CA': Regions.NORTH_AMERICA,
  'MX': Regions.NORTH_AMERICA,
  'US': Regions.NORTH_AMERICA,

  // Oceania
  'AS': Regions.OCEANIA,
  'AU': Regions.OCEANIA,
  'CK': Regions.OCEANIA,
  'FJ': Regions.OCEANIA,
  'PF': Regions.OCEANIA,
  'GU': Regions.OCEANIA,
  'KI': Regions.OCEANIA,
  'MH': Regions.OCEANIA,
  'FM': Regions.OCEANIA,
  'NR': Regions.OCEANIA,
  'NC': Regions.OCEANIA,
  'NZ': Regions.OCEANIA,
  'NU': Regions.OCEANIA,
  'NF': Regions.OCEANIA,
  'MP': Regions.OCEANIA,
  'PW': Regions.OCEANIA,
  'PG': Regions.OCEANIA,
  'PN': Regions.OCEANIA,
  'WS': Regions.OCEANIA,
  'SB': Regions.OCEANIA,
  'TK': Regions.OCEANIA,
  'TO': Regions.OCEANIA,
  'TV': Regions.OCEANIA,
  'VU': Regions.OCEANIA,
  'WF': Regions.OCEANIA,

  // South/Southeast Asia
  'BD': Regions.SOUTH_SOUTHEAST_ASIA,
  'BT': Regions.SOUTH_SOUTHEAST_ASIA,
  'BN': Regions.SOUTH_SOUTHEAST_ASIA,
  'KH': Regions.SOUTH_SOUTHEAST_ASIA,
  'IN': Regions.SOUTH_SOUTHEAST_ASIA,
  'ID': Regions.SOUTH_SOUTHEAST_ASIA,
  'LA': Regions.SOUTH_SOUTHEAST_ASIA,
  'MY': Regions.SOUTH_SOUTHEAST_ASIA,
  'MV': Regions.SOUTH_SOUTHEAST_ASIA,
  'MM': Regions.SOUTH_SOUTHEAST_ASIA,
  'NP': Regions.SOUTH_SOUTHEAST_ASIA,
  'PH': Regions.SOUTH_SOUTHEAST_ASIA,
  'SG': Regions.SOUTH_SOUTHEAST_ASIA,
  'LK': Regions.SOUTH_SOUTHEAST_ASIA,
  'TH': Regions.SOUTH_SOUTHEAST_ASIA,
  'TL': Regions.SOUTH_SOUTHEAST_ASIA,
  'VN': Regions.SOUTH_SOUTHEAST_ASIA,

  // Sub-Saharan Africa
  'AO': Regions.SUB_SAHARAN_AFRICA,
  'BJ': Regions.SUB_SAHARAN_AFRICA,
  'BW': Regions.SUB_SAHARAN_AFRICA,
  'BF': Regions.SUB_SAHARAN_AFRICA,
  'BI': Regions.SUB_SAHARAN_AFRICA,
  'CV': Regions.SUB_SAHARAN_AFRICA,
  'CF': Regions.SUB_SAHARAN_AFRICA,
  'TD': Regions.SUB_SAHARAN_AFRICA,
  'KM': Regions.SUB_SAHARAN_AFRICA,
  'CG': Regions.SUB_SAHARAN_AFRICA,
  'CD': Regions.SUB_SAHARAN_AFRICA,
  'CI': Regions.SUB_SAHARAN_AFRICA,
  'DJ': Regions.SUB_SAHARAN_AFRICA,
  'GQ': Regions.SUB_SAHARAN_AFRICA,
  'ER': Regions.SUB_SAHARAN_AFRICA,
  'ET': Regions.SUB_SAHARAN_AFRICA,
  'GA': Regions.SUB_SAHARAN_AFRICA,
  'GM': Regions.SUB_SAHARAN_AFRICA,
  'GH': Regions.SUB_SAHARAN_AFRICA,
  'GN': Regions.SUB_SAHARAN_AFRICA,
  'GW': Regions.SUB_SAHARAN_AFRICA,
  'KE': Regions.SUB_SAHARAN_AFRICA,
  'LS': Regions.SUB_SAHARAN_AFRICA,
  'LR': Regions.SUB_SAHARAN_AFRICA,
  'MG': Regions.SUB_SAHARAN_AFRICA,
  'MW': Regions.SUB_SAHARAN_AFRICA,
  'ML': Regions.SUB_SAHARAN_AFRICA,
  'MR': Regions.SUB_SAHARAN_AFRICA,
  'MU': Regions.SUB_SAHARAN_AFRICA,
  'YT': Regions.SUB_SAHARAN_AFRICA,
  'MZ': Regions.SUB_SAHARAN_AFRICA,
  'NA': Regions.SUB_SAHARAN_AFRICA,
  'NE': Regions.SUB_SAHARAN_AFRICA,
  'NG': Regions.SUB_SAHARAN_AFRICA,
  'RW': Regions.SUB_SAHARAN_AFRICA,
  'RE': Regions.SUB_SAHARAN_AFRICA,
  'SH': Regions.SUB_SAHARAN_AFRICA,
  'ST': Regions.SUB_SAHARAN_AFRICA,
  'SN': Regions.SUB_SAHARAN_AFRICA,
  'SC': Regions.SUB_SAHARAN_AFRICA,
  'SL': Regions.SUB_SAHARAN_AFRICA,
  'SO': Regions.SUB_SAHARAN_AFRICA,
  'ZA': Regions.SUB_SAHARAN_AFRICA,
  'SS': Regions.SUB_SAHARAN_AFRICA,
  'SZ': Regions.SUB_SAHARAN_AFRICA,
  'TZ': Regions.SUB_SAHARAN_AFRICA,
  'TG': Regions.SUB_SAHARAN_AFRICA,
  'UG': Regions.SUB_SAHARAN_AFRICA,
  'ZM': Regions.SUB_SAHARAN_AFRICA,
  'ZW': Regions.SUB_SAHARAN_AFRICA,

  // West/Central Asia
  'AF': Regions.WEST_CENTRAL_ASIA,
  'AM': Regions.WEST_CENTRAL_ASIA,
  'AZ': Regions.WEST_CENTRAL_ASIA,
  'BH': Regions.WEST_CENTRAL_ASIA,
  'CY': Regions.WEST_CENTRAL_ASIA,
  'IR': Regions.WEST_CENTRAL_ASIA,
  'IQ': Regions.WEST_CENTRAL_ASIA,
  'IL': Regions.WEST_CENTRAL_ASIA,
  'JO': Regions.WEST_CENTRAL_ASIA,
  'KW': Regions.WEST_CENTRAL_ASIA,
  'KG': Regions.WEST_CENTRAL_ASIA,
  'LB': Regions.WEST_CENTRAL_ASIA,
  'OM': Regions.WEST_CENTRAL_ASIA,
  'PK': Regions.WEST_CENTRAL_ASIA,
  'PS': Regions.WEST_CENTRAL_ASIA,
  'QA': Regions.WEST_CENTRAL_ASIA,
  'SA': Regions.WEST_CENTRAL_ASIA,
  'SY': Regions.WEST_CENTRAL_ASIA,
  'TJ': Regions.WEST_CENTRAL_ASIA,
  'TM': Regions.WEST_CENTRAL_ASIA,
  'AE': Regions.WEST_CENTRAL_ASIA,
  'UZ': Regions.WEST_CENTRAL_ASIA,
  'YE': Regions.WEST_CENTRAL_ASIA,

  // Western Europe
  'AX': Regions.WESTERN_EUROPE,
  'AD': Regions.WESTERN_EUROPE,
  'AT': Regions.WESTERN_EUROPE,
  'BE': Regions.WESTERN_EUROPE,
  'FO': Regions.WESTERN_EUROPE,
  'FR': Regions.WESTERN_EUROPE,
  'DE': Regions.WESTERN_EUROPE,
  'GI': Regions.WESTERN_EUROPE,
  'GR': Regions.WESTERN_EUROPE,
  'GL': Regions.WESTERN_EUROPE,
  'GG': Regions.WESTERN_EUROPE,
  'VA': Regions.WESTERN_EUROPE,
  'IS': Regions.WESTERN_EUROPE,
  'IE': Regions.WESTERN_EUROPE,
  'IM': Regions.WESTERN_EUROPE,
  'IT': Regions.WESTERN_EUROPE,
  'JE': Regions.WESTERN_EUROPE,
  'LI': Regions.WESTERN_EUROPE,
  'LU': Regions.WESTERN_EUROPE,
  'MT': Regions.WESTERN_EUROPE,
  'MC': Regions.WESTERN_EUROPE,
  'NL': Regions.WESTERN_EUROPE,
  'NO': Regions.WESTERN_EUROPE,
  'PT': Regions.WESTERN_EUROPE,
  'SM': Regions.WESTERN_EUROPE,
  'ES': Regions.WESTERN_EUROPE,
  'SJ': Regions.WESTERN_EUROPE,
  'SE': Regions.WESTERN_EUROPE,
  'CH': Regions.WESTERN_EUROPE,
  'GB': Regions.WESTERN_EUROPE,
};