import { create } from "zustand";
import { produce } from "immer";
import { v4 as uuid } from "uuid";

import { type IClientCard as ICard} from "@last-haven/shared/types.js";
import { NUM_SURVEY_QUESTIONS, ESSENTIAL_SURVEY_QUESTIONS, NUM_SURVEY_QUESTIONS_TO_ASK } from "@last-haven/shared/constants.js";
import { shuffleArray } from "@last-haven/shared/randomisation.js";

import { 
  createUser,
  selectCardFromPair, 
  markAnswered, 
  setPresentationTimeStamp, 
  setTaskDescriptionShown, 
  giveConsent, 
  feedback, 
  createSession, 
  getSession, 
  createSurvey, 
  getSurvey, 
  completeSurvey,
  setResults,
  getRankings,
  selectRankingFromPair,
  setRankingsPresentationTimeStamp,
} from "../api.js";

const drawSurveyQuestions = () => {
  const questions = Array(NUM_SURVEY_QUESTIONS)
    .fill(0)
    .map((q, ii) => ii + 1);

  const NUM_ESSENTIAL_QUESTIONS = ESSENTIAL_SURVEY_QUESTIONS.length;
  const optionalQuestions = questions.filter(
    (q) => !ESSENTIAL_SURVEY_QUESTIONS.includes(q)
  );
  if (NUM_SURVEY_QUESTIONS_TO_ASK < NUM_ESSENTIAL_QUESTIONS) {
    throw new Error(
      "NUM_SURVEY_QUESTIONS_TO_ASK must be greater than or equal to the number of essential questions"
    );
  }
  if (NUM_SURVEY_QUESTIONS_TO_ASK > NUM_SURVEY_QUESTIONS) {
    throw new Error(
      "NUM_SURVEY_QUESTIONS_TO_ASK must be less than or equal to the total number of questions"
    );
  }

  const numOptionalQuestionsToAsk =
    NUM_SURVEY_QUESTIONS_TO_ASK - NUM_ESSENTIAL_QUESTIONS;
  return shuffleArray(optionalQuestions)
    .slice(0, numOptionalQuestionsToAsk)
    .concat(ESSENTIAL_SURVEY_QUESTIONS)
    .sort((a, b) => a - b);
};

interface IStore {
  userId: string;
  userPending: boolean;
  userPromise: Promise<void>;
  loading: boolean;
  sessionId: string;
  sessionPromise: Promise<void>;
  consent: boolean;
  consentTimeStamp: Date;
  dilemmas: ICard[][];
  selections: string[];
  surveyExists: boolean;
  surveyCompleted: boolean;
  surveyQuestions: number[];
  surveyFirst: boolean;
  cardSectionOrder: string[];
  instructionsViewed: boolean;
  triggerWarningViewed: boolean;
  feedbackLocation: string;
  resetAttempted: boolean;
  resultsUploaded: boolean;
  rankings: any[];
  rankingsSessionId: string;
  rankingCategory: string;
  rankingScores: { [key: string]: number };
  initUser: () => Promise<void>;
  createUser: () => Promise<void>;
  createSession: (userId?: string) => Promise<void>;
  validateSession: (args: {userId: string, sessionId: string}) => Promise<void>;
  updateSession: (args: {sessionId: string, dilemmas: ICard[][]}) => void;
  resetSession: () => Promise<void>;
  createSurvey: (args: {userId: string, sessionId: string}) => Promise<void>;
  validateSurvey: (args: {userId: string, sessionId: string}) => Promise<void>;
  completeSurvey: (args: {answers: {index: number, questionId: string, question: string, answer: string | number | boolean, created: Date}}) => Promise<void>;
  selectCard: (args: { scenarioOrder: number; cardOrder: number; targetValue: boolean }) => Promise<void>;
  selectCardFromPair: (args: { scenarioOrder: number; chosen: number }) => Promise<void>;
  markAnswered: (args: { scenarioOrder: number; cardOrder: number[] }) => Promise<void>;
  setPresentationTimeStamp: (args: { scenarioOrder: number; presentationTimeStamp: Date }) => Promise<void>;
  giveConsent: () => Promise<void>;
  createFeedback: (args: { page: string, message: string }) => Promise<void>;
  setInstructionsViewed: () => void;
  setTriggerWarningViewed: () => void;
  setFeedbackLocation: (args: { location: string }) => void;
  setResults: (args: { mostSavedClass: string, leastSavedClass: string, humansVsSpeciesScore: number, effectivenessScore: number, charismaticSpeciesScore: number, ecologicallyImportantSpeciesScore: number, economicImpactScore: number, ecologicalImpactScore: number }) => Promise<void>;
  getRankings: () => Promise<void>;
  selectRankingCard: (args: { scenarioOrder: number; cardOrder: number; targetValue: boolean }) => void;
  selectRankingFromPair: (args: { scenarioOrder: number; chosen: number }) => Promise<void>;
  setRankingsPresentationTimeStamp: (args: { scenarioOrder: number; presentationTimeStamp: Date }) => Promise<void>; 
  setRankingCategory: (rankingCategory: string) => void;
  updateRankingScores: (args: {winner: string, loser: string}) => void;
}

export const useStore = create<IStore>((set, get) => ({
  userId: "",
  userPending: false,
  userPromise: Promise.resolve(),
  sessionId: "",
  sessionPromise: Promise.resolve(),
  surveyExists: false,
  surveyCompleted: false,
  surveyQuestions: [],
  surveyFirst: null,
  consent: false,
  consentTimeStamp: null,
  dilemmas: [],
  selections: [],
  cardSectionOrder: [],
  loading: true,
  instructionsViewed: false,
  triggerWarningViewed: false,
  feedbackLocation: "",
  resetAttempted: false,
  resultsUploaded: false,
  rankings: [],
  rankingsSessionId: "",
  rankingCategory: "",
  rankingScores: {},
  async initUser() {
    const { 
      userPending, 
      createUser, 
      createSession, 
      validateSurvey,
    } = get();
    if (userPending) return;

    const userId = localStorage.getItem("userId");
    if (!userId) {
      const userPromise = createUser();
      set({ userPromise });
      return ;
    }
    set({ userId });
    
    // always create a new session
    createSession(userId).then(() => validateSurvey({ userId, sessionId: get().sessionId }))

    return;

    /* old code that may be useful if we need to keep state across reloads in development
    const sessionId = sessionStorage.getItem("sessionId");
    if (!sessionId) {
      await createSession(userId); // Pass userId explicitly
      return;
    }
  
    await validateSession({ userId, sessionId });
    if (!surveyExists) {
      await get().validateSurvey({ userId, sessionId });
    }
    */
  },
  
  async createUser() {
    console.log('creating user')
    const userId = uuid();
    set({ userId, userPending: true, loading: true});

    const response = await createUser({ _id: userId, userAgent: navigator.userAgent, userPreviousSite: document.referrer || "unknown" });
    if (!response.success){
      console.error("Failed to create user:", response.error);
      localStorage.removeItem("userId");
      sessionStorage.clear();
    }
    localStorage.setItem("userId", userId);
    set({ userPending: false });
    get().updateSession(response.data);
    const { sessionId } = get();
    get().createSurvey({ userId, sessionId });
  },
  
  async createSession(userId = get().userId) { // Default to using userId from state if not provided
    console.log('creating session')
    set({ loading: true });
    const sessionPromise = createSession({ userId, userAgent: navigator.userAgent });
    set({ sessionPromise: sessionPromise.then(() => {}) });
    const response = await sessionPromise;
    if (!response.success){
      if (response.error === "User not found") {
        localStorage.removeItem("userId");
        await get().initUser();
        return;
      }
      if (!get().resetAttempted){
        set({ resetAttempted: true });
        await get().resetSession();
      }
    } else {
      get().updateSession(response.data);
    }
    return;
  },
  
  async validateSession({ userId, sessionId }) {
    set({ loading: true });
    const sessionResponse = await getSession({ userId, sessionId });
    if (!sessionResponse.success) {
      throw new Error("Failed to get session: " + sessionResponse.error);
    }
    get().updateSession({ sessionId, ...sessionResponse.data });
  },

  updateSession({ sessionId, dilemmas }) {
    sessionStorage.setItem("sessionId", sessionId);
    set({ sessionId, dilemmas, loading: false, resetAttempted: false, resultsUploaded: false });
  },
  async resetSession() {

    set({ sessionId: null, dilemmas: null, loading: true });
    sessionStorage.clear();
    const sessionPromise = get().createSession();
    set({ sessionPromise });
    await sessionPromise;
    get().validateSurvey({ userId: get().userId, sessionId: get().sessionId });
    return;
  },

  async createSurvey({userId, sessionId}) {
    const response = await createSurvey({ userId, sessionId });
    if (!response.success) {
      throw new Error("Failed to create survey: " + response.error);
    }
    const { surveyFirst } = response.data;
    set({ surveyExists: true, surveyFirst, surveyQuestions: drawSurveyQuestions() });
  },

  async validateSurvey({ userId, sessionId }) {
    if (!sessionId) return;
    const response = await getSurvey({ userId, sessionId });
    if (!response.success) {
      throw new Error("Failed to get survey: " + response.error);
    }
    const surveyCompleted = response.data?.elements?.length > 0;
    const { surveyFirst } = response.data;
    set({ surveyExists: true, surveyCompleted, surveyFirst, surveyQuestions: drawSurveyQuestions() });
  },

  async completeSurvey({ answers }) {
    const { userId, sessionId } = get();
    const response = await completeSurvey({ userId, sessionId, answers });
    if (!response.success) {
      throw new Error("Failed to complete survey: " + response.error);
    }
    set({ surveyCompleted: true });
  },

  async selectCard({
    scenarioOrder,
    cardOrder,
    targetValue,
  }: {
    scenarioOrder: number;
    cardOrder: number;
    targetValue: boolean;
  }) {
    const {sessionId, consent, consentTimeStamp, dilemmas} = get();
    const originalValue = dilemmas[scenarioOrder][cardOrder].chosen;
    const updateToTarget = (state: IStore, targetValue: boolean) => {
      const updateElement = state.dilemmas[scenarioOrder][cardOrder];
      updateElement.chosen = targetValue;
    }
    set(produce((state) => updateToTarget(state, targetValue)));
    const response = await selectCardFromPair({ sessionId, scenarioOrder, cardOrder, consent, consentTimeStamp });
    if (!response.success) {
      set(produce((state) => set(produce((state) => updateToTarget(state, originalValue)))));
    }
  },
  async selectCardFromPair({
    scenarioOrder,
    chosen,
  }: {
    scenarioOrder: number;
    chosen: number;
  }) {
    const {selectCard, dilemmas} = get();
    if (scenarioOrder === undefined || scenarioOrder < 0 || scenarioOrder >= dilemmas.length)
      throw new Error("Invalid scenarioOrder:" + scenarioOrder);
    if (chosen < 0 || chosen >= dilemmas[scenarioOrder].length)
      throw new Error(`Invalid selection. Chose ${chosen} of ${dilemmas[scenarioOrder].length}.`);
    const targetValue = true;
    const cardOrder = chosen;
    
    await selectCard({ scenarioOrder, cardOrder, targetValue });
  },

  async markAnswered({ scenarioOrder, cardOrder }) {
    const { sessionId, consent, consentTimeStamp } = get();
    set(produce(state => {
      state.dilemmas[scenarioOrder].forEach(p => p.answered = Date.now());
    }))
    await markAnswered({ sessionId, scenarioOrder, cardOrder, consent, consentTimeStamp });
  },

  async setPresentationTimeStamp({ scenarioOrder, presentationTimeStamp }) {
    const { sessionId } = get();
    await setPresentationTimeStamp({ sessionId, scenarioOrder, presentationTimeStamp });
  },

  async setTaskDescriptionShown({ scenarioOrder }) {
    const { sessionId } = get();
    await setTaskDescriptionShown({ sessionId, scenarioOrder });
  },
  async giveConsent() {
    const consentTimeStamp = new Date();
    set(
      produce((state) => {
        state.consent = true;
        state.consentTimeStamp = consentTimeStamp;
      })
    );
    const response = await giveConsent({ userId: (get() as {userId: String}).userId, consent: true, consentTimeStamp });
    if (!response.success) {
      set(
        produce((state) => {
          state.consent = false;
          state.consentTimeStamp = null;
        })
      );
    }
  },

  async createFeedback({ page, message }: { page: string, message: string }) {
    const { userId, sessionId } = get() as {userId: string, sessionId: string};
    const response = await feedback({ userId, sessionId, page, message });
    if (!response.success) {
      throw new Error("Failed to submit feedback: " + response.error);
    }
  },
  setInstructionsViewed(){
    set({ instructionsViewed: true })
  },
  setTriggerWarningViewed(){
    set({ triggerWarningViewed: true })
  },
  setFeedbackLocation({ location }) {
    set({ feedbackLocation: location });
  },
  async setResults({ mostSavedClass, leastSavedClass, humansVsSpeciesScore, effectivenessScore, charismaticSpeciesScore, ecologicallyImportantSpeciesScore, economicImpactScore, ecologicalImpactScore }) {
    const { userId, sessionId, surveyFirst, resultsUploaded } = get();
    if (!resultsUploaded) {
      const response = await setResults({
        userId,
        sessionId, 
        beforeSurvey: ! surveyFirst,
        mostSavedClass, 
        leastSavedClass, 
        humansVsSpeciesScore, 
        effectivenessScore, 
        charismaticSpeciesScore,
        ecologicallyImportantSpeciesScore,
        economicImpactScore, 
        ecologicalImpactScore 
      });
      set({ resultsUploaded: true });
    }
  },
  async getRankings() {
    const { sessionPromise } = get();
    await sessionPromise;
    const { userId, sessionId, rankingCategory } = get();
    if (! rankingCategory) {
      return {};
    }
    set({ loading: true , rankings: [] });
    const isSpecies = rankingCategory === "species";
    const response = await getRankings({ userId, sessionId, isSpecies });
    if (!response.success) {
      throw new Error("Failed to get ranking rounds: " + response.error);
    }
    set({ rankings: response.data, rankingsSessionId: response.data[0][0].rankingsSessionId, loading: false });
    return response.data;
  },
  async selectRankingCard({ scenarioOrder, cardOrder, targetValue }) {
    const updateToTarget = (state: IStore, targetValue: boolean) => {
      const updateElement = state.rankings[scenarioOrder][cardOrder];
      updateElement.chosen = targetValue;
      updateElement.answered = true;
    }
    set(produce((state) => updateToTarget(state, targetValue)));
  },
  async selectRankingFromPair({ scenarioOrder, chosen }) {
    const { rankingsSessionId, consent, consentTimeStamp, selectRankingCard } = get();
    selectRankingCard({ scenarioOrder, cardOrder: chosen, targetValue: true });
    selectRankingCard({ scenarioOrder, cardOrder: chosen === 0 ? 1 : 0, targetValue: false });
    const response = await selectRankingFromPair({ rankingsSessionId, scenarioOrder, cardOrder: chosen, consent, consentTimeStamp });
    if (!response.success) {
      throw new Error("Failed to select ranking from pair: " + response.error);
    }
  },
  async setRankingsPresentationTimeStamp({ scenarioOrder, presentationTimeStamp }) {
    const { rankingsSessionId } = get();
    await setRankingsPresentationTimeStamp({ rankingsSessionId, scenarioOrder, presentationTimeStamp });
  },

  setRankingCategory(rankingCategory: string) {
    if (["species", "buildings"].indexOf(rankingCategory) === -1) {
      throw new Error("rankingCategory must be `buildings` or `species`");
    }
    set({ rankingCategory });
  },
  updateRankingScores({ winner, loser}: { winner: string, loser: string }) {
    // calculate an elo score based on the winner and loser
    // first look up the current score of each and if it doesn't exist, set it to 1000
    set(produce((state) => {
      const { rankingScores } = state;
      const winnerScore = rankingScores[winner] || 1000;
      const loserScore = rankingScores[loser] || 1000;
      const expectedWinner = 1 / (1 + 10 ** ((loserScore - winnerScore) / 400));
      const expectedLoser = 1 / (1 + 10 ** ((winnerScore - loserScore) / 400));
      rankingScores[winner] = winnerScore + 32 * (1 - expectedWinner);
      rankingScores[loser] = loserScore + 32 * (0 - expectedLoser);
    }));
  }
}))
