import React, { Suspense, useMemo } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  useRouteError,
  useLocation,
  Outlet,
  redirect,
  ScrollRestoration,
} from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./services/i18n";
import { MenuBar } from "./components/MenuBar";
import { Consent } from "./containers/Consent";
import { Game } from "./containers/Game";
import { Ranking } from "./containers/Ranking";
import { About } from "./containers/About";
import { FAQ } from "./containers/FAQ";
import { FeedbackModal } from "./containers/Feedback";
import { InstructionsModal } from "./containers/Instructions";
import { TriggerWarningModal } from "./containers/TriggerWarning";
import { SocialShareModal } from "./containers/SocialShare";
import { Landing } from "./containers/Landing";
import { SurveyOffer } from "./containers/SurveyOffer";
import { Survey } from "./containers/Survey";
import { NotFound } from "./containers/404";
import { BeforeRankingModal } from "./containers/BeforeRankingModal";
import { useStore } from "./hooks/useStore";
import { generateResults } from "@last-haven/shared/score";

import {
  speciesMap,
  buildingsMap,
  NUM_DILEMMAS,
} from "@last-haven/shared/constants";
import useImagePreload from "./hooks/useImagePreload";
import { Loading } from "./components/Loading";
import { Attributes } from "./containers/Attributes";
import { ResultsSummary } from "./containers/ResultsSummary";
import { useEffect } from "react";

function Layout() {
  const location = useLocation();
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.loadNamespaces([
      "about",
      "card",
      "consent",
      "faq",
      "feedback",
      "game",
      "instructions",
      "results",
      "survey",
      "survey-offer",
      "trigger-warning",
    ]);
  }, [i18n]);

  const imagesToPreload = useMemo(() => {
    const speciesImages = Object.values(speciesMap).map((s) => s.image);
    const buildingImages = Object.values(buildingsMap).map((b) => b.image);
    return [...speciesImages, ...buildingImages];
  }, []);
  useImagePreload(imagesToPreload);

  return (
    <div className="h-[100svh] w-screen max-h-[100svh] overflow-x-scroll flex flex-col bg-lastHaven-gray-50 font-sans text-lastHaven-black">
      <div className="relative z-50 max-w-screen-xxl mx-auto sm:w-full min-w-375px bg-lastHaven-gray-50 fixed top-0">
        <MenuBar />
      </div>
      <div
        key={location.pathname}
        className="overflow-y-scroll  w-full flex-grow flex flex-col"
      >
        <div className="max-w-screen-xxl mx-auto sm:w-full min-w-375px bg-lastHaven-gray-50 flex-grow flex flex-col">
          <Suspense fallback={<Loading />}>
            <InstructionsModal />
            <TriggerWarningModal />
            <FeedbackModal />
            <SocialShareModal />
            <Suspense fallback={<Loading />}>
              <Outlet />
            </Suspense>
            <ScrollRestoration />
          </Suspense>
        </div>
      </div>
    </div>
  );
}
function ErrorBoundary() {
  const error = useRouteError();
  const { t } = useTranslation("app");
  console.log(error);

  return (
    <div className="flex flex-col items-center justify-center h-full max-h-[100svh] text-6 text-lastHaven-primary-main">
      <h2 className="font-700 text-8">{t("Oops! Something went wrong.")}</h2>
      <p>{t("We apologize for the inconvenience.")}</p>
      <p>{t("Please try refreshing the page or come back later.")}</p>
    </div>
  );
}
var router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <ErrorBoundary />,
    loader: async () => {
      const { initUser } = useStore.getState();
      console.log("running init user");
      initUser();
      return {};
    },
    shouldRevalidate: () => false,
    children: [
      { index: true, element: <Landing /> },
      { path: "about", element: <About /> },
      { path: "faq", element: <FAQ /> },
      {
        path: "consent",
        element: <Consent />,
        loader: async ({ request }) => {
          const url = new URL(request.url);
          const nextPath = url.searchParams.get("next") || "/play";
          await Promise.all([
            useStore.getState().userPromise,
            useStore.getState().sessionPromise,
          ]);
          return { nextPath };
        },
      },
      {
        path: "play",
        element: <Game />,
        loader: async () => {
          await useStore.getState().sessionPromise;
          const { consent, surveyFirst, dilemmas } = useStore.getState();
          if (!consent) {
            return redirect("/consent?next=/play");
          }
          const nextPath = surveyFirst ? "/survey-offer" : "/results";
          if (dilemmas?.every((pair) => pair.some((c) => c.chosen))) {
            return redirect(nextPath);
          }
          return { nextPath };
        },
      },
      {
        path: "ranking",
        element: <Outlet />,
        loader: async () => {
          const { consent } = useStore.getState();
          if (!consent) {
            throw redirect("/consent?next=/ranking");
          }
          return {};
        },
        children: [
          {
            path: "play",
            element: <Ranking />,
            loader: async () => {
              const rankingCategory = useStore.getState().rankingCategory;
              if (!rankingCategory) {
                throw redirect("/ranking");
              }
              const isSpecies = rankingCategory === "species";
              const { getRankings, userId, sessionId } = useStore.getState();
              await getRankings({ userId, sessionId, isSpecies });
              return {};
            },
          },
          {
            index: true,
            element: <BeforeRankingModal />,
            loader: async () => {
              const rankingCategory = useStore.getState().rankingCategory;
              if (rankingCategory) {
                throw redirect("/ranking/play");
              }
              return {};
            },
          },
        ],
      },
      {
        path: "survey-offer",
        element: <SurveyOffer />,
      },
      {
        path: "survey",
        element: <Survey />,
        loader: async () => ({ nextPath: "/results" }),
      },
      {
        path: "results",
        element: <ResultsSummary />,
        loader: async () => {
          const { dilemmas, setResults } = useStore.getState();
          const dilemmasComplete =
            dilemmas?.length === NUM_DILEMMAS &&
            dilemmas?.every((pair) => pair.some((c) => c.chosen));

          if (!dilemmasComplete) {
            return redirect("/play");
          }
          const results = generateResults(dilemmas);
          setResults(results);
          return results;
        },
      },
      { path: "attributes", element: <Attributes /> },
      { path: "*", element: <NotFound /> },
    ],
  },
]);

export default function App() {
  return <RouterProvider router={router} fallbackElement={<Loading />} />;
}
