import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "./Button";
import { useFlip } from "../hooks/useFlip";
import { speciesMap, buildingsMap } from "@last-haven/shared/constants";
import { PercentageVisualizer } from "./index";
import "../styles/animations.css";
import "../styles/colors.css";

// Helper function to decode HTML entities
// Needed because some i18next interpolations don't handle HTML entities correctly
function decodeHtmlEntities(text) {
  const textArea = document.createElement("textarea");
  textArea.innerHTML = text;
  return textArea.value;
}

const forTranslation = (s) =>
  s.toLowerCase().replace(/ /g, "-").replace(/\//g, "-");

export function CardPair({ pair, onSelect, onShowDescription }) {
  const [fadingOut, setFadingOut] = useState(false);
  const { t } = useTranslation("card");
  const { flip, isFront, isFlipping, toFront } = useFlip();

  const showDescriptionHandler = () => {
    if (isFront) {
      onShowDescription();
    }
    flip();
  };

  const selectHandler = (index) => {
    if (fadingOut) return;
    setFadingOut(true);
    setTimeout(() => {
      onSelect(index);
      setFadingOut(false);
    }, 450);
  };

  return (
    <>
      <div className="flex flex-col sm:flex-row justify-between max-sm:mb-6 sm:py-8">
        <div className={`w-342px sm:w-426px max-sm:pb-4`}>
          <Card
            dataTestId="left"
            {...pair[0]}
            isFront={isFront}
            isFlipping={isFlipping}
            toFront={toFront}
            fadingOut={fadingOut}
            onSelect={() => selectHandler(0)}
          />
        </div>
        <div className={`w-342px sm:w-426px max-sm:pt-4`}>
          <Card
            dataTestId="right"
            {...pair[1]}
            isFront={isFront}
            isFlipping={isFlipping}
            toFront={toFront}
            fadingOut={fadingOut}
            onSelect={() => selectHandler(1)}
          />
        </div>
      </div>
      <div className="sm:h-94px pb-6 sm:pb-10 w-full align-middle text-center">
        <Button
          secondary={!isFront}
          text={isFront ? t("button-label") : t("button-alternate-label")}
          onClick={showDescriptionHandler}
          disabled={isFlipping}
        />
      </div>
    </>
  );
}

export function Card({
  region,
  species,
  landUse,
  cardIsDefault,
  chosen,
  onSelect,
  isFront,
  isFlipping,
  toFront,
  fadingOut,
  size = "large",
  sectionOrder,
  dataTestId,
}) {
  const { t } = useTranslation("card");

  const sizedClasses =
    size === "large"
      ? "w-342px sm:w-426px text-3.5 sm:mx-auto"
      : "w-171px sm:w-252px leading-9px text-2 m-2 ";

  const cardProps = {
    region,
    species,
    landUse,
    cardIsDefault,
    chosen,
    onSelect,
    size,
    sectionOrder,
    t,
    dataTestId,
  };
  return (
    <div
      className={`flex flex-col bg-transparent ${sizedClasses}  font-inter animate-fade-in animate-duration-500 ${
        fadingOut && "animate-fade-out"
      }`}
    >
      <div
        className={`relative transition-transform duration-700 ${
          isFront ? "" : "transform rotate-y-180"
        }`}
      >
        {(isFront && !isFlipping) || (isFlipping && !toFront) ? (
          <CardFront {...cardProps} />
        ) : (
          <CardBack {...cardProps} />
        )}
      </div>
    </div>
  );
}

export function CardFront({
  species,
  landUse,
  cardIsDefault,
  chosen,
  onSelect,
  size = "large",
  sectionOrder,
  t,
  dataTestId,
}) {
  const sizedClasses =
    size === "large"
      ? "min-w-342px sm:min-w-426px h-383px sm:h-508px sm:p-4 rounded-3"
      : "w-171px sm:w-252px sm:h-296px rounded-2 p-2";

  const isSpecies = !!species;
  const category = isSpecies ? species.name : landUse.name;
  const map = isSpecies ? speciesMap : buildingsMap;
  const image = map[category].image;
  const label = t(
    `${isSpecies ? "species" : "buildings"}.${forTranslation(category)}.${
      isSpecies ? "plural" : "label"
    }`
  );
  const imageLabel = t(
    `${isSpecies ? "species" : "buildings"}-label${
      !isSpecies && cardIsDefault ? "-default" : ""
    }`
  );
  return (
    <div
      className={`${sizedClasses} bg-lastHaven-white flex flex-col max-sm:active:(outline-lastHaven-primary-main outline-4 outline-solid) sm:hover:(outline-lastHaven-primary-main outline-4 outline-solid) ${
        chosen ? "outline-lastHaven-primary-main outline-4 outline-solid" : ""
      }`}
      style={{ boxShadow: "0px 1.61px 16.103px 0px rgba(0, 0, 0, 0.10)" }}
      onClick={onSelect}
      data-testid={dataTestId}
    >
      <div className="flex flex-col h-47px max-sm:m-1.5 sm:h-73px ">
        <div className="font-700 text-5 leading-6 sm:text-6 sm:leading-8 text-center">
          {t(`title${cardIsDefault ? "-default" : ""}`)}
        </div>
        <div className="text-center text-3 leading-4.5 sm:text-4 sm:leading-6">
          ({t(`subtitle${cardIsDefault ? "-default" : ""}`)})
        </div>
      </div>
      <div className="flex flex-col justify-center items-center h-218px sm:h-267px my-2 sm:my-3">
        <img src={image} alt={label} className="w-auto h-195px mb-1" />
        <div className="flex flex-col justify-center items-center w-full h-49px sm:h-60px">
          <div className="text-center leading-19px">{imageLabel}</div>
          <div className="font-700 text-5 leading-6 sm:text-6 sm:leading-29px">
            {label}
          </div>
          {!isSpecies && (
            <div className="text-center leading-19px">
              {t(`buildings-label-2`)}
            </div>
          )}
        </div>
      </div>
      <div
        className={`flex justify-center items-center w-322px sm:w-394px h-76px sm:h-93px bg-lastHaven-gray-50 rounded-2 px-4.5 mx-auto`}
      >
        {isSpecies ? (
          <div className="flex flex-col py-2 justify-between">
            <PercentageVisualizer
              percentFull={parseInt(species.percentage, 10)}
            />
            <div className="text-center text-11.44px sm:text-3.5 font-600 mt-2.5 sm:mt-3">
              {t("percentage-text", { percentage: species.percentage, label })}
            </div>
          </div>
        ) : (
          <div className="flex justify-between">
            <div className="flex text-lastHaven-secondary items-center">
              <span className="font-700 text-6 sm:text-8 mr-1 sm:mr-2 font-sans">
                {landUse.effectiveness}x
              </span>
              <div className="flex items-center">
                <img
                  src="/images/people.svg"
                  className="w-8 sm:w-10 h-auto"
                  alt="people"
                />
              </div>
            </div>
            <div className="flex items-center ml-2.5 sm:ml-3 font-600 text-3 leading-3.5 sm:text-3.5 sm:leading-4">
              {t("effectiveness-text", {
                effectiveness: landUse.effectiveness,
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function CardBack({
  region,
  species,
  landUse,
  cardIsDefault,
  onSelect,
  sectionOrder,
  chosen,
  size = "large",
  t,
}) {
  const sizedClasses =
    size === "large"
      ? "sm:min-w-342px sm:min-w-426px h-383px sm:h-508px sm:py-6 sm:px-4 px-5 pt-5 rounded-3"
      : "w-171px sm:w-252px sm:h-296px text-2.5 leading-4 rounded-2 p-3";

  const BackRow = ({ label, text }) => (
    <div className="flex pb-3">
      <span className="sm:font-700">{decodeHtmlEntities(text)}</span>
    </div>
  );
  const isSpecies = !!species;
  const translationName = isSpecies
    ? forTranslation(species.name)
    : forTranslation(landUse.name);
  const category = isSpecies ? "species" : "buildings";
  const label = t(`${category}.${translationName}.label`);
  const defaultSuffix = cardIsDefault ? "-default" : "";
  // region is in the game namespace because it is shared across cards
  const regionText = t(`game:region.${forTranslation(region)}`);
  const textOne = t(`description-${category}${defaultSuffix}.one`, {
    regionText,
  });
  const textTwo = t(`description-${category}${defaultSuffix}.two`);
  const textThree = t(`description-${category}${defaultSuffix}.three`, {
    label,
  });
  const textFour = t(`description-${category}${defaultSuffix}.four`, {
    percentage: isSpecies ? species.percentage : "",
    speciesPlural: t(`${category}.${translationName}.plural`),
    effectiveness: !isSpecies ? landUse.effectiveness : "",
  });

  return (
    <>
      <div
        className={`font-sans sm:text-4 ${sizedClasses} text-lastHaven-white bg-lastHaven-black flex flex-col transform-rotate-y-180 ${
          chosen ? "outline-lastHaven-primary-main outline-4 outline-solid" : ""
        }`}
        style={{ boxShadow: "0px 1.61px 16.103px 0px rgba(0, 0, 0, 0.10)" }}
      >
        <BackRow text={textOne} />
        <BackRow text={textTwo} />
        <BackRow text={textThree} />
        <BackRow text={textFour} />
      </div>
    </>
  );
}
export function CardHeaderRow({ size, left, middle, right }) {
  const sizedClasses =
    size === "large"
      ? "h-116px sm:h-144px sm:w-144px py-2px"
      : "w-233px h-85px gap-1";
  return (
    <div className="flex justify-center items-center">
      <div className="flex-grow" />
      <div
        className={`flex justify-center items-center w-auto ${sizedClasses}`}
      >
        {middle}
      </div>
      <div className="flex-grow" />
    </div>
  );
}

export function LeftPanel({ size, text, image }) {
  if (image) {
    return (
      <div className="flex items-center">
        <div
          className={`text-right capitalize hyphens-auto break-words ${
            size === "large" ? "min-w-93px" : "min-w-75px"
          }`}
        >
          {text}
        </div>
        <img
          src={image}
          alt={text}
          className={`${
            size === "large"
              ? "h-64px sm:h-full w-auto ml-3 sm:ml-4"
              : "h-47.32px w-auto ml-2"
          }`}
        />
      </div>
    );
  }
  return (
    <div
      className={`text-right capitalize hyphens-auto break-words ${
        size === "large" ? "max-w-141px" : "max-w-76.31px"
      }`}
    >
      {text}
    </div>
  );
}

export function RightPanel({ size, text, image }) {
  if (image) {
    return (
      <div className="flex items-center">
        <img
          src={image}
          alt={text}
          className={`${
            size === "large"
              ? "h-64px sm:h-full w-auto mr-3 sm:mr-4"
              : "h-47.32px w-auto mr-2"
          }`}
        />
        <div
          className={`text-left capitalize hyphens-none ${
            size === "large" ? "max-w-93px" : "max-w-75px"
          }`}
        >
          {text}
        </div>
      </div>
    );
  }
  return <div className="text-left capitalize hyphens-none">{text}</div>;
}
