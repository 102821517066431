import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "./Button.jsx";
import { speciesMap, buildingsMap } from "@last-haven/shared/constants.js";
import { PercentageVisualizer } from "./index.jsx";
import "../styles/animations.css";
import "../styles/colors.css";

interface ICardProps {
  species: any;
  landUse: any;
  chosen: any;
  onSelect: () => void;
  fadingOut: any;
  sectionOrder: any;
}
// Helper function to decode HTML entities
// Needed because some i18next interpolations don't handle HTML entities correctly
function decodeHtmlEntities(text) {
  const textArea = document.createElement("textarea");
  textArea.innerHTML = text;
  return textArea.value;
}
const forTranslation = (s) => s.toLowerCase().replace(/ /g, "-");

export function CardPair({ pair, onSelect, onShowDescription }) {
  const [fadingOut, setFadingOut] = useState(false);
  const { t } = useTranslation("card");


  const selectHandler = (index) => {
    if (fadingOut) return;
    setFadingOut(true);
    setTimeout(() => {
      onSelect(index);
      setFadingOut(false);
    }, 450);
  };

  return (
    <>
      <div className="flex flex-col sm:flex-row justify-between max-sm:mb-6 sm:py-8">
        <div className={`w-342px sm:w-426px max-sm:pb-4`}>
          <Card
            {...pair[0]}
            fadingOut={fadingOut}
            onSelect={() => selectHandler(0)}
          />
        </div>
        <div className={`w-342px sm:w-426px max-sm:pt-4`}>
          <Card
            {...pair[1]}
            fadingOut={fadingOut}
            onSelect={() => selectHandler(1)}
          />
        </div>
      </div>
    </>
  );
}

export function Card({
  species,
  landUse,
  chosen,
  onSelect,
  fadingOut,
  sectionOrder,
}: ICardProps): JSX.Element {
  const { t } = useTranslation("card");

  const isSpecies = !!species;
  const map = isSpecies ? speciesMap : buildingsMap;
  const category = isSpecies ? species : landUse;
  const image = map[category].image;
  const labelOrPlural = isSpecies ? "plural" : "label";
  const label = t(`${isSpecies ? "species" : "buildings"}.${forTranslation(category)}.${labelOrPlural}`);

  const sizedClasses = "min-w-342px sm:min-w-426px h-383px sm:h-508px sm:p-4 rounded-3";

  return (
    <div
      className={`flex flex-col bg-transparent ${sizedClasses}  font-inter animate-fade-in animate-duration-500 ${
        fadingOut && "animate-fade-out"
      }`}
    >
      <div
        className={`relative transition-transform duration-700`}
      >
        <div 
          className={`${sizedClasses} bg-lastHaven-white flex flex-col max-sm:active:(outline-lastHaven-primary-main outline-4 outline-solid) sm:hover:(outline-lastHaven-primary-main outline-4 outline-solid) ${
            chosen ? "outline-lastHaven-primary-main outline-4 outline-solid" : ""
          }`}
          style={{ boxShadow: "0px 1.61px 16.103px 0px rgba(0, 0, 0, 0.10)" }}
          onClick={onSelect}
        >
          <div className="flex justify-center items-center h-384px w-394px">
            
            <img
              src={image}
              alt={label}
              className="min-h-260px h-auto max-h-384px min-w-260px w-auto max-w-394px object-cover"
            />
          </div>
          
          <div className="flex flex-col items-center justify-center h-73px">
            <h2 className="text-24px font-semibold text-lastHaven-black">
              {label}
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
}
